@import '../../../../../styles/variables.breakpoints.module.scss';
@import '../../../../../styles/mixins.scss';

.show-cards-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 16px;
  height: 100%;
  padding-bottom: 40px;
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .card-column-0 {
    grid-area: 1 / 1 / 3 / 2;
    height: 100%;

    min-height: 630px;

    @media (max-width: 768px) {
      min-height: 560px;
    }
    @media (max-width: 600px) {
      min-height: 313px;
      display: grid;
    }
  }
  .card-column-1 {
    grid-area: 1 / 2 / 2 / 3;
    height: 100%;

    @media (max-width: 600px) {
      min-height: 313px;
      display: grid;
    }
  }
  .card-column-2 {
    grid-area: 2 / 2 / 3 / 3;
    height: 100%;

    @media (max-width: 600px) {
      min-height: 313px;
      display: grid;
    }
  }
  .card-column-3 {
    grid-area: 3 / 1 / 4 / 2;
    height: 307px;

    @media (max-width: 600px) {
      min-height: 313px;
      display: grid;
    }
  }
  .card-column-4 {
    grid-area: 3 / 2 / 4 / 3;
    height: 307px;

    @media (max-width: 600px) {
      min-height: 313px;
      display: grid;
    }
  }
  .card-column-5 {
    grid-area: 4 / 1 / 5 / 2;
    height: 307px;

    @media (max-width: 600px) {
      min-height: 313px;
      display: grid;
    }
  }
  .card-column-6 {
    grid-area: 4 / 2 / 5 / 3;
    height: 307px;

    @media (max-width: 600px) {
      min-height: 313px;
      display: grid;
    }
  }
  .card-column-7 {
    grid-area: 5 / 1 / 6 / 2;
    height: 307px;

    @media (max-width: 600px) {
      min-height: 313px;
      display: grid;
    }
  }
  .card-column-8 {
    grid-area: 5 / 2 / 6 / 3;
    height: 307px;

    @media (max-width: 600px) {
      min-height: 313px;
      display: grid;
    }
  }
  .card-column-9 {
    grid-area: 6 / 1 / 7 / 2;
    height: 307px;

    @media (max-width: 600px) {
      min-height: 313px;
      display: grid;
    }
  }
  .card-column-10 {
    grid-area: 6 / 2 / 7 / 3;
    height: 307px;

    @media (max-width: 600px) {
      min-height: 313px;
      display: grid;
    }
  }
  .card-column-11 {
    grid-area: 7 / 1 / 8 / 2;
    height: 307px;

    @media (max-width: 600px) {
      min-height: 313px;
      display: grid;
    }
  }
  .card-column-12 {
    grid-area: 7 / 2 / 8 / 3;
    height: 307px;

    @media (max-width: 600px) {
      min-height: 313px;
      display: grid;
    }
  }

  .card-column-13 {
    grid-area: 8 / 1 / 9 / 2;
    height: 307px;

    @media (max-width: 600px) {
      min-height: 313px;
      display: grid;
    }
  }
  .card-column-14 {
    grid-area: 8 / 2 / 9 / 3;
    height: 307px;

    @media (max-width: 600px) {
      min-height: 313px;
      display: grid;
    }
  }

  .card-column-15 {
    grid-area: 9 / 1 / 10 / 2;
    height: 307px;

    @media (max-width: 600px) {
      min-height: 313px;
      display: grid;
    }
  }
  .card-column-16 {
    grid-area: 9 / 2 / 10 / 3;
    height: 307px;

    @media (max-width: 600px) {
      min-height: 313px;
      display: grid;
    }
  }

  .card-column-17 {
    grid-area: 10 / 1 / 11 / 2;
    height: 307px;

    @media (max-width: 600px) {
      min-height: 313px;
      display: grid;
    }
  }
  .card-column-18 {
    grid-area: 10 / 2 / 11 / 3;
    height: 307px;

    @media (max-width: 600px) {
      min-height: 313px;
      display: grid;
    }
  }
  @media (max-width: 600px) {
    .card-column-0,
    .card-column-1,
    .card-column-2,
    .card-column-3,
    .card-column-4,
    .card-column-5,
    .card-column-6,
    .card-column-7,
    .card-column-8,
    .card-column-9,
    .card-column-10,
    .card-column-11,
    .card-column-12,
    .card-column-13,
    .card-column-14,
    .card-column-15,
    .card-column-16,
    .card-column-17,
    .card-column-18 {
      min-height: 313px;
      display: grid;
      height: 100%;
    }
  }

  .full-height-single-column {
    grid-area: 1 / auto / 3 / span 1;
    height: 100%;
    min-height: 630px;

    @media (max-width: 768px) {
      min-height: 560px;
    }
    @media (max-width: 600px) {
      min-height: 313px;
      display: grid;
      height: 100%;
    }
  }
}

.experience-section {
  display: flex;
  gap: 24px;
  flex-direction: column;

  @include breakpoint-up(lg) {
    gap: 40px;
  }
  h2 {
    margin: 0px;
    font-weight: 350;
    text-shadow: 0px 0px 16px var(--black);

    @include breakpoint-up(md) {
      font-size: 28px;
    }

    @include breakpoint-up(lg) {
      font-size: 32px;
      line-height: 120%;
    }
  }

  .carousel-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 16px;
    padding-bottom: 24px;

    .indicator {
      height: 6px;
      width: 6px;
      margin: 0 2px;
      background-color: transparent;
      border-radius: 50%;
      display: inline-block;
      border: 1px solid var(--gray-400);
      transition: background-color 0.6s ease;
      z-index: 3;
    }

    .indicator-position {
      text-align: center;
      width: 100%;
      z-index: 2;
    }

    .active-indicator {
      background-color: var(--gray-400);
    }
  }
}
