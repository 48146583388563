@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.shows-card {
  position: relative;
  max-width: unset;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  display: flex;
  opacity: 1;

  @media (max-width: 600px) {
    max-height: 313px;
  }

  @include breakpoint-up(md) {
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
  }
  @include breakpoint-up(lg) {
    max-width: 792px;
    height: 100%;
    width: 100%;
  }

  .image-container {
    position: relative;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;

    .image-wrapper {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      border: 1px solid var(--color-neutral-gray-800, #303030);
      background: #000;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
      object-fit: cover;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }

  .shows-card-content {
    position: absolute;
    bottom: 0;
    align-items: flex-start;
    text-align: left;
    display: flex;
    flex-direction: column;
    padding: 24px;
    width: 100%;
    @media (max-width: 600px) {
      padding: 0 16px;
      padding-bottom: 12px;
    }

    .shows-card-content__inner {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: flex-start;
      text-align: left;
      width: 100%;

      @include breakpoint-up(lg) {
        width: 60%;
        gap: 16px;
      }
      h2 {
        margin: 0px;

        @include breakpoint-up(lg) {
          font-size: 32px;
        }
      }

      h3 {
        margin: 0px;
      }

      h6 {
        text-transform: none;
        margin: 0px;
        font-size: 16px;
      }

      p {
        margin: 0px;
        text-align: left;
        font-size: 16px;
        font-weight: 350;
        line-height: 150%;
        color: var(--color-neutral-white);
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        @include breakpoint-down(md) {
          height: calc(2 * 1.5 * 16px);
        }
      }

      .btn-container {
        display: block;
        width: 100%;
        margin: auto 0px;

        @include breakpoint-up(md) {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 16px;
          width: auto;
          margin: 0px;
        }

        @include breakpoint-up(lg) {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          gap: 16px;
        }

        button,
        a {
          margin: auto 0px auto;
          width: 100%;
          &:first-child {
            margin-bottom: 8px;
            @include breakpoint-up(md) {
              margin: auto 0px auto;
            }
          }
          @include breakpoint-up(md) {
            width: 100%;
          }
        }
      }
    }
  }

  .shows-card-content__inner {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    text-align: left;
    width: 100%;
    h2 {
      margin: 0px;

      @include breakpoint-up(lg) {
        font-size: 40px;
      }
    }

    h3 {
      margin: 0px;
    }

    h6 {
      text-transform: none;
      margin: 0px;
      font-size: 16px;
    }

    p {
      margin: 0px;
      text-align: left;
      font-size: 16px;
      font-weight: 350;
      line-height: 150%;
      color: var(--color-neutral-white);
    }

    .btn-container {
      display: block;
      width: 100%;
      margin: auto 0px;

      @include breakpoint-up(md) {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;
        width: auto;
        margin: 0px;
      }

      @include breakpoint-up(lg) {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 16px;
      }

      button {
        margin: auto 0px auto;
        width: 100%;
        &:first-child {
          margin-bottom: 8px;
          @include breakpoint-up(md) {
            margin: auto 0px auto;
          }
        }
        @include breakpoint-up(md) {
          width: 100%;
        }
      }
    }
  }
}
