@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.parent-container {
  background-color: var(--body-bg-color);

  @include breakpoint-up(md) {
    &[data-type-landscape='false'] {
      background-color: inherit;
    }
    height: fit-content;
  }
}

.mounted {
  animation: inAnimation 250ms ease-in;
}

.unmounted {
  animation: outAnimation 500ms ease-in;
}

@keyframes inAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes outAnimation {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.container {
  padding: 0 24px;
  width: 100%;
  bottom: 0;
  max-width: 696px;

  heyebrow {
    display: block;
  }

  @include breakpoint-up(md) {
    &[data-type-landscape='false'] {
      position: absolute !important;
      top: 35%;
      transform: translateY(-50%);
      z-index: 2;
    }
  }

  @include breakpoint-up(lg) {
    text-align: left;
    max-width: 696px !important;
  }

  .card-details-container {
    padding: 0px 0px 50px 0px;
    margin-left: 0;

    @include breakpoint-up(md) {
      margin-left: 8px;
      padding: 0;

      p {
        max-width: 90%;
      }
    }

    @include breakpoint-up(lg) {
      margin-left: 8px;
      padding: 0;
    }

    .card-details {
      .headline-container {
        position: relative;
        z-index: 1;

        .eyebrow {
          heyebrow {
            font-size: 11px;
            font-weight: var(--font-weight-extra-bold);
            letter-spacing: 2.2px;
            margin-bottom: 0px;
            text-transform: uppercase;

            @include breakpoint-up(md) {
              padding-top: 0px;
              font-size: var(--font-size-body-xs);
              font-weight: var(--font-weight-extra-bold);
            }
          }
        }

        .headline {
          font-weight: var(--font-weight-extra-bold);
          margin-top: 8px;
        }

        .headlinegap {
          margin-top: 8px;
          h1 {
            line-height: 120%;
            font-size: 28px;
            padding-bottom: 24px;
            text-align: center;
            text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.4), 0px 4px 60px rgba(0, 0, 0, 0.5);

            @include breakpoint-up(md) {
              padding-top: 0px;
              font-size: var(--font-size-specialty-headline-desktop);
              padding-bottom: 16px;
            }

            @include breakpoint-up(lg) {
              text-align: left;
            }
          }
        }
      }
    }

    .btn-container {
      min-height: 36px;
      gap: 8px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(141px, 1fr));

      @include breakpoint-up(md) {
        &[data-type-landscape='false'] {
          display: flex;
          gap: 14px;
          width: auto;
          justify-content: center;
        }
      }

      @include breakpoint-up(lg) {
        &[data-type-landscape='false'] {
          display: flex;
          gap: 14px;
          width: auto;
          justify-content: left;
        }
      }

      button {
        margin: auto 0px auto;
        width: 100%;
        height: 100%;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 350;
        letter-spacing: 0.9px;

        &:first-child {
          @include breakpoint-up(md) {
            &[data-type-landscape='false'] {
              margin: auto 0px auto;
            }
          }
        }

        @include breakpoint-up(md) {
          &[data-type-landscape='false'] {
            width: auto;
          }
        }
      }

      a {
        height: 100%;
        display: flex !important;
        justify-content: center;
        align-items: center;
        margin: auto 0px auto;
      }
    }
  }

  &[data-type-landscape='true'] {
    .headlinegap {
      h1 {
        font-size: 28px !important;
      }
    }

    .eyebrow {
      heyebrow {
        font-size: 11px !important;
      }
    }
  }
}
