@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.card {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  flex-direction: column;
  gap: 8px;

  @include breakpoint-up(md) {
    p {
      height: 72px;
      max-height: 72px;
      margin: 0;
    }
  }

  .media-column {
    margin-top: var(--spacing-1);
  }

  h3 {
    margin: 0;
  }
  .card-container__bg-img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .image-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0.3;

    img {
      border-radius: 16px;
    }
  }

  .col-wrapper--content {
    text-align: center;
    width: 100%;

    @include breakpoint-up(md) {
      text-align: left;
    }
  }

  .col-wrapper--media {
    padding-right: 0;
  }

  .col-wrapper--content {
    padding-right: 0;

    h2 {
      margin-bottom: 16px;

      + h5 {
        margin-top: -8px;
      }
    }

    h2,
    p {
      margin-top: 0;
    }

    .img-subheadline {
      margin-bottom: 5px;
    }

    p {
      @include body-copy(md);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@include breakpoint-down(sm) {
  .media-column {
    margin-bottom: var(--spacing-1);
  }
}
