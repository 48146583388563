@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.hero-full-screen {
  max-width: 100%;
  width: 100vw;
  height: 100vh;
  min-height: 100%;

  position: relative;
  margin-bottom: calc(var(--footer-margin-top) * -1);

  // targetVersion: v2.0.0
  // position: fixed;
  // bottom: -10px;

  transition: all 0.5 ease;

  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;

  .slide {
    max-width: 100%;
    width: 100vw;
    height: auto;
    min-height: 100vh;
    height: 100vh;
    overflow: hidden;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .content {
    position: absolute;
    top: 190px;
    z-index: 99;
    width: 100%;
    margin-bottom: 30px;
    text-align: center;
    padding: 0 15px;

    @include breakpoint-up(sm) {
      top: 205px;
    }

    @include breakpoint-up(md) {
      top: 220px;
    }

    @include breakpoint-up(lg) {
      top: 165px;
    }

    @include breakpoint-up(xxl) {
      top: 185px;
    }

    .headings {
      h1,
      h2,
      h3,
      h4 {
        font-weight: var(--font-weight-default);
      }

      h3,
      h4 {
        margin-bottom: var(--spacing-1);

        @include breakpoint-up(md) {
          margin-bottom: 0;
        }
      }
    }

    .brand-logo-xl {
      display: none;

      @include breakpoint-up(md) {
        display: block;
        margin: 15px auto;
        max-width: 560px;
      }

      @include breakpoint-up(xxl) {
        max-width: 1360px;
      }
    }
  }

  .pill-container {
    position: relative;
    margin: 0 auto;

    @include breakpoint-up(sm) {
      padding: 0;
    }

    @include breakpoint-up(sm) {
      padding: 0 15px;
    }

    @include breakpoint-up(md) {
      max-width: 800px;
    }

    @include breakpoint-up(lg) {
      max-width: 860px;
    }

    @include breakpoint-up(xxl) {
      max-width: 1100px;
    }

    .slick-arrow-custom {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 20;
      padding: 7px;
      cursor: pointer;

      &.slick-arrow-custom--prev {
        left: 20px;

        @include breakpoint-up(md) {
          left: 0;
        }
      }

      &.slick-arrow-custom--next {
        right: 20px;

        @include breakpoint-up(md) {
          right: 0;
        }
      }
    }

    svg {
      height: 14px;
    }
  }

  // targetVersion: v2.0.0
  // .scroll-indicator {
  //   position: fixed;
  //   left: 50%;
  //   bottom: 0;
  //   transform: translate(-50%, -50%);

  //   p {
  //     text-transform: uppercase;
  //     margin-bottom: 0;
  //   }

  //   .caret {
  //     @include arrow(down, sm);
  //     margin-left: var(--spacing-1);
  //   }
  // }

  .bottom-slogan {
    @include body-copy(xs);
    position: absolute;
    bottom: 16px;
    width: 100%;
    text-align: center;
  }
}
