@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.wrapper-container {
  padding-top: 32px;
  padding-bottom: 32px;
  min-height: 848px;

  @include breakpoint-up(sm) {
    min-height: 800px;
  }

  @include breakpoint-up(md) {
    min-height: unset;
    height: 100vh;
    box-sizing: content-box;
  }

  @include breakpoint-up(lg) {
    padding-top: 72px;
    padding-bottom: 72px;
  }
}

.sign-up-container {
  position: relative;
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: transparent;
  padding: 16px;

  @include breakpoint-up(md) {
    align-items: center;
    padding: 24px;
    min-height: 100vh;
  }

  .media-image {
    position: absolute;
    width: 100%;
    height: 240px;
    overflow: hidden;
    margin-top: 0;
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    z-index: 0;

    @include breakpoint-up(md) {
      height: 100vh;
      background-position: bottom 50% right 15%;
    }

    @media (min-width: 1440px) {
      background-position: bottom 50% right 0%;
    }
  }

  .video-container {
    position: absolute;
    height: 240px;
    top: 0;
    left: 0;
    z-index: 0;
    overflow: hidden;

    @include breakpoint-up(md) {
      height: 100%;
      width: 100vw;
    }

    video {
      object-fit: cover;
      min-height: 240px;
      width: 100%;
      object-position: center bottom;

      @include breakpoint-up(md) {
        height: 100vh;
      }
    }
  }

  .media-overlay {
    position: absolute;
    width: 100vw;
    height: 240px;
    overflow: hidden;
    top: 0;
    right: 0;
    z-index: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 72.08%, #000000 100%), linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 21.34%);

    @include breakpoint-up(md) {
      height: 100vh;
      background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 21.34%), linear-gradient(180deg, rgba(0, 0, 0, 0) 81.59%, #000000 100%),
        linear-gradient(270deg, rgba(0, 0, 0, 0) 55.6%, #000000 100%);
    }

    @include breakpoint-up(lg) {
      background: linear-gradient(270deg, rgba(0, 0, 0, 0) 58.93%, #000 100%), linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 21.34%),
        linear-gradient(180deg, rgba(0, 0, 0, 0) 81.59%, #000 100%);
    }
  }

  .form-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    @include breakpoint-up(lg) {
      width: 1600px;
      margin: auto;
    }
  }

  .form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
    background-color: transparent;
    z-index: 1;
    position: relative;
    top: 197px;
    flex-wrap: wrap;

    @include breakpoint-up(md) {
      justify-content: center;
      top: unset;
      width: 288px;
    }

    @include breakpoint-up(lg) {
      width: 500px;
    }

    .text-container {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;
      justify-content: center;
      align-items: flex-start;

      h2 {
        margin: 0;
        font-size: 24px;
        font-weight: 600;
        line-height: 120%;
        margin-bottom: 30px; //Counteracts -30px margin-top on .paragraph-container

        @include breakpoint-up(lg) {
          font-size: var(--font-size-h1-desktop);
          font-weight: 400;
        }
      }

      p {
        font-weight: var(--font-weight-light);
        letter-spacing: 0.8px;
        line-height: 120%;
      }
    }

    p {
      margin: 0 !important;
    }

    .signup-form {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;

      .input-container {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 2px;

        input {
          color: var(--white);
          border-radius: 8px;
          outline: none;
          border: 1px solid var(--gray-500);
          font-size: 16px;
          background: var(--Transparent-Black-50, rgba(0, 0, 0, 0.5));
          padding: 8px 16px;
          position: relative;
          display: flex;
          cursor: auto;

          &.input-error {
            border-color: red;

            &:focus {
              border-color: red;
            }

            &:focus + label,
            &:not(:placeholder-shown) + label {
              color: red;
            }
          }

          &::placeholder {
            color: var(--gray-300);
            letter-spacing: 0.8px;
          }

          &:focus {
            border-color: var(--white);
            background: var(--black);

            &::placeholder {
              color: transparent;
            }
          }

          &:focus + label,
          &:not(:placeholder-shown) + label {
            left: 16px;
            top: 0;
            font-size: 12px;
            font-weight: 300;
            background: var(--black);
            padding: 0 4px;
            color: var(--white);
            display: flex;
          }

          + label {
            position: absolute;
            transform: translateY(-50%);
            left: 14px;
            top: 25%;
            transition: all 0.25s ease-out;
            pointer-events: none;
            color: transparent;
          }
        }
      }

      .warning-container {
        color: #f52500;
        font-size: 14px;
        font-weight: 350;
        line-height: 150%;
        display: flex;
        flex-direction: row;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        margin-left: 18px;

        span {
          text-align: left;
        }
      }

      .checkbox-container {
        input[type='checkbox'] {
          padding: 0;
          height: initial;
          width: initial;
          display: none;
          cursor: pointer;

          //custom checkmark
          &:checked + label::after {
            content: '';
            display: block;
            position: absolute;
            top: 18px;
            left: 8px;
            transform: translateY(-50%) rotate(45deg);
            width: 8px;
            height: 16px;
            border: solid #000;
            border-width: 0 2px 2px 0;
          }

          &:checked + label::before {
            background-color: var(--white);
            border-color: var(--white);
          }
        }

        label {
          position: relative;
          color: var(--white);
          cursor: pointer;
          align-self: center;
          display: flex;
          gap: 8px;
          padding: 8px 0px;

          //custom checkbox
          &::before {
            content: '';
            cursor: pointer;
            -webkit-appearance: none;
            appearance: none;
            background-color: rgba(var(--black-rgba), 0.8);
            border: 1px solid var(--gray-500);
            border-radius: 8px;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
            padding: 11px;
            display: inline-block;
            position: relative;
            vertical-align: middle;
            cursor: pointer;
          }
        }

        .warning-container {
          margin-left: 32px;
        }
      }

      .btn-container {
        align-self: stretch;
        display: flex;
        flex-direction: column;

        @include breakpoint-up(md) {
          align-self: unset;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 16px;
        }

        button,
        a {
          font-size: 14px;
        }

        &.center-align {
          @include breakpoint-up(md) {
            align-self: center;
          }
        }
      }
    }

    form {
      p:last-child {
        color: var(--gray-200);
      }
    }
  }

  &.landscape {
    .media-overlay,
    .media-image,
    .video-container {
      height: 100%;
    }

    .form-container {
      width: 100%;

      .signup-form {
        gap: 8px;

        .checkbox-container > label {
          padding: 0;
        }
      }

      button {
        margin-bottom: 0;
      }
    }
  }
}
