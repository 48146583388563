@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.hero-infobar-link {
  display: flex;
  gap: 8px;
  align-items: center;
  height: 24px;
  p {
    margin: 0;
    color: var(--gray-100);
    font-weight: var(--font-weight-extra-bold);
    font-size: var(--font-size-body-sm);
    line-height: 14px;
    @include breakpoint-up(lg) {
      font-size: var(--font-size-body-md);
      line-height: 16px;
    }
  }
}
