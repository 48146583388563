@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.card-row-container {
  display: flex;
  gap: 24px;
  flex-direction: column;
  padding: 40px 24px;

  @include breakpoint-up(lg) {
    gap: 40px;
  }
  h2 {
    margin: 0px;
    font-weight: 350;
    text-shadow: 0px 0px 16px var(--black);

    @include breakpoint-up(md) {
      font-size: 28px;
    }

    @include breakpoint-up(lg) {
      font-size: 32px;
      line-height: 120%;
    }
  }

  .carousel-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 16px;
    padding-bottom: 24px;

    .indicator {
      height: 6px;
      width: 6px;
      margin: 0 2px;
      background-color: transparent;
      border-radius: 50%;
      display: inline-block;
      border: 1px solid var(--gray-400);
      transition: background-color 0.6s ease;
      z-index: 3;
    }

    .indicator-position {
      text-align: center;
      width: 100%;
      z-index: 2;
    }

    .active-indicator {
      background-color: var(--gray-400);
    }
  }
}
