@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.location-container {
  margin-bottom: var(--spacing-2);

  @include breakpoint-up(md) {
    margin-bottom: 0;
  }

  &.is-last-child {
    @include breakpoint-down(sm) {
      margin-bottom: 0;
    }
  }

  .location {
    position: relative;
    background-color: var(--gray-900);
    padding: var(--spacing-1);
    border: 1px solid var(--black);
    cursor: pointer;

    @include breakpoint-up(md) {
      text-align: center;
      margin-bottom: 40px;

      &:after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }
    }

    &:hover {
      border-color: var(--white);
      transition: all 0.5s linear;

      strong {
        color: var(--gray-300);
        transition: all 0.5s linear;
      }
    }

    .location-content {
      @include breakpoint-up(md) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: center;
      }

      .location-content-inner {
        width: 100%;

        @include breakpoint-down(sm) {
          display: flex;
          align-items: center;

          * {
            flex: 1;
          }
        }
      }
    }

    h3 {
      @include heading(display);
      font-weight: var(--font-weight-semi-bold);
      text-transform: uppercase;
      display: inline;
      margin: 0;

      @include breakpoint-up(md) {
        text-align: center;
        display: block;
        text-align: center;
      }
    }
  }
}
