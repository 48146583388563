@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.breadcrumb-container {
  margin-bottom: 16px;
  padding: 8px 0px;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    gap: 8px;
    color: var(--gray-100);

    li {
      display: flex;
      align-items: center;
      a {
        color: var(--gray-100);
        font-size: var(--font-size-body-xs);
        font-weight: var(--font-weight-light);
        letter-spacing: 0.6px;
        display: flex;
        align-items: center;

        @include breakpoint-up(md) {
          font-size: var(--font-size-body-sm);
          font-weight: 300;
          letter-spacing: 0.7px;
        }
        div:hover {
          text-decoration: underline;
        }
        svg {
          margin-right: 8px;
        }
      }
    }
  }
}
