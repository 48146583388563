@import '../../../styles/variables.breakpoints.module.scss';

.pill-wrapper {
  text-align: center;

  .pill {
    position: relative;
    display: inline-block;
    border: 1px solid var(--gray-900);
    border-radius: 25px;
    background-color: var(--gray-900);
    width: 100%;
    padding: 15px 35px;

    @include breakpoint-up(md) {
      border-radius: 1000px;
      width: auto;
      padding: 7px;
    }

    .pill-inner {
      @include breakpoint-up(md) {
        display: flex;
        align-items: center;
      }
    }

    button {
      display: inline-block;
      margin-top: 7px;

      @include breakpoint-up(md) {
        margin-top: 0;
      }
    }

    h4,
    h5 {
      @include breakpoint-up(md) {
        display: inline-block;
        width: auto;
      }
    }

    h4 {
      text-transform: uppercase;

      @include breakpoint-up(md) {
        margin: 0 12px;
      }

      + h4 {
        margin-top: 3px;

        @include breakpoint-up(xxl) {
          margin-top: 0;
        }
      }
    }

    h5 {
      text-transform: uppercase;
      margin: 0 5px;

      @include breakpoint-up(xxl) {
        margin: 0 8px;
      }
    }

    button {
      @include breakpoint-up(md) {
        margin-left: 3px;
      }
    }
  }
}
