@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.hero-general-info {
  width: 100%;
  position: relative;
  z-index: 5;
  margin-bottom: 0px;
  &.has-header {
    margin-top: var(--module-spacing);
    margin-bottom: 20px;
  }
  .hero-general-info-container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
    max-width: 1080px;
    z-index: 1;
    .general-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 16px;
      text-align: center;
      padding: 24px 0px;
      h2 {
        margin: 0;
        font-weight: var(--font-weight-extra-bold);
        text-transform: lowercase;
        line-height: 33.6px;
      }
      p {
        margin: 0;
        font-size: var(--font-size-body-sm);
        font-weight: var(--font-weight-light);
      }
      .show-info {
        display: flex;
        padding: 8px 16px;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        @include breakpoint-up(md) {
          display: flex;
          align-items: center;
          padding: 8px 0px;
          flex-direction: row;
        }
        @include breakpoint-up(lg) {
          display: flex;
          align-items: center;
          padding: 16px 0px;
          flex-direction: row;
        }
        p {
          margin: 0;
          font-size: var(--font-size-body-sm);
          font-weight: var(--font-weight-extra-bold);
        }
      }
      .justify-between {
        justify-content: center;
        @include breakpoint-up(md) {
          justify-content: space-between;
        }
      }
      .justify-around {
        justify-content: center;
        @include breakpoint-up(md) {
          justify-content: space-around;
        }
      }
    }
  }
  .general-info__bg-img {
    height: 178px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    bottom: 0;
    right: 0;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @include breakpoint-up(md) {
      opacity: 0.4;
    }
  }
}
