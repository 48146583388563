.play-button-wrapper {
  transition: opacity 0.3s ease;
}

.play-button-wrapper:hover {
  fill-opacity: 0.9;
}

.visually-hidden {
  display: none;
  visibility: hidden;
}

.tooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px;
  background-color: transparent;
  color: white;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
}
