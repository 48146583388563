@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.experience-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  backdrop-filter: blur(4px);
  background: rgba(0, 0, 0, 0.1);
  border-radius: 7.35px 7.35px 0px 0px;
  height: 100%;

  @include breakpoint-up(md) {
    border-radius: 8px 8px 0px 0px;
  }

  @include breakpoint-up(lg) {
    border-radius: 16px 16px 0 0;
  }

  .image-container {
    position: relative;
    width: 100%;
    padding-top: 48%;
    border-radius: 7.35px 7.35px 0px 0px;
    overflow: hidden;

    @include breakpoint-up(md) {
      padding-top: 74.35%;
      border-radius: 8px 8px 0px 0px;
    }

    @include breakpoint-up(lg) {
      padding-top: 48%;
      border-radius: 16px 16px 0 0;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      min-width: 100%;
      object-fit: cover;
    }
  }

  .experience-card-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
    padding: 16px;

    .experience-card-content__inner {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;

      h2 {
        margin: 0px;
        font-size: 18px;
        font-weight: 600;

        @include breakpoint-up(lg) {
          font-size: 20px;
        }
      }

      h3,
      h6 {
        width: 100%;
        margin: 0px;
      }

      p {
        margin: 0px;
        text-align: left;
        font-size: 16px;
        font-weight: 350;
        line-height: 150%;
        color: var(--color-neutral-white);
      }
    }
  }

  .btn-container {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    gap: 8px;
    width: 100%;
    padding-left: 16px;

    @include breakpoint-up(lg) {
      display: flex;
      align-items: flex-start;
      flex-direction: row;
      justify-content: flex-start;
      gap: 16px;
    }

    button {
      margin: 0px;
      width: 100%;

      &:first-child {
        margin-bottom: 8px;

        @include breakpoint-up(md) {
          margin: 0px;
        }
      }

      @include breakpoint-up(md) {
        width: 100%;
      }
    }
  }
}
