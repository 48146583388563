@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.card {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  padding: 12px;
  border-radius: 16px;
  background-color: var(--gray-900);

  @include breakpoint-up(md) {
    padding: 16px;
  }

  .image-container {
    margin: var(--spacing-0);
  }

  h3 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: var(--font-size-h3-mobile) !important;
    font-weight: var(--font-weight-light) !important;

    @include breakpoint-up(md) {
      font-size: var(--font-size-h3-desktop) !important;
    }
  }
  h4 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .card-description {
    @include body-copy(sm);

    p {
      font-weight: var(--font-weight-medium);
      font-size: var(--font-size-body-sm);
      margin-top: 0;
      margin-bottom: 15px;
    }

    ul {
      list-style-image: url('/assets/list-item-check-mark.png');
      padding-left: 20px;
      margin-top: 24px;
      margin-bottom: 0;

      li {
        margin-bottom: 8px;
        overflow-wrap: break-word;
      }
    }
  }

  .card-details {
    @include body-copy(xs);
  }
}

.cards-plan-visit-page {
  width: 100%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-height: 100%;
  gap: 16px;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
  max-width: 1080px;

  @include breakpoint-up(sm) {
    display: block;
  }
  @include breakpoint-up(md) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include breakpoint-up(lg) {
    display: flex;
  }
}

.transparent-background {
  background-color: transparent !important;
  padding: 40px 0;

  button {
    margin: auto 0px auto;
    width: 100%;
    @include breakpoint-up(md) {
      width: unset;
    }
  }
}
