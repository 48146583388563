@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.header-button {
  display: inline-flex;
  height: 72px;
  padding: 8px 24px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border: 0px solid rgba(171, 171, 171, 0);
  background: linear-gradient(251deg, #20ffff 19.17%, #05c3dd 75.2%);
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.8px;
  text-transform: lowercase;

  &:hover {
    border: 0px solid rgba(171, 171, 171, 0);
    background: linear-gradient(248deg, #05c3dd 27.85%, #20ffff 81.23%);
  }
}
