@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.artwork-container {
  top: 80px;
  max-width: 1600px !important;
  background-size: 100% 82%;
  background-repeat: no-repeat;

  .btn-container-mobile {
    margin-top: 16px;
  }

  .extended-title-mobile * {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .extended-title-desktop * {
    white-space: wrap;
  }

  @include breakpoint-up(md) {
    padding: 0 24px !important;
  }

  heyebrow {
    display: block;
  }

  .artwork-details-container {
    padding-bottom: 70px;
    margin-left: 0;

    .artwork-details {
      .headline-container {
        position: relative;
        z-index: 1;

        .eyebrow {
          heyebrow {
            color: var(--gray-300);
            font-size: 11px;
            font-weight: 600;
            margin-top: 60px;
            letter-spacing: 2.2px;

            @include breakpoint-up(lg) {
              font-size: 12px;
              margin-top: 80px;
            }
          }
        }

        .headline {
          margin-bottom: 8px !important;
        }

        .description {
          font-size: 14px;
          font-weight: 350;
          margin-bottom: 10px;

          @include breakpoint-up(md) {
            font-size: 32px;
            font-weight: 350;
            margin-bottom: 40px;
          }
        }

        .headlinegap {
          display: flex;
          align-items: baseline;
          margin-bottom: 8px;
          h1 {
            font-weight: 350;
            font-size: 28px;
          }
          @include breakpoint-up(md) {
            h1 {
              font-size: 32px;
              font-weight: 250;
            }
          }

          @include breakpoint-up(lg) {
            h1 {
              font-size: 48px;
            }
          }

          .btn-container {
            display: flex;
            gap: 16px;

            button {
              line-height: normal;
              font-size: 18px;
            }
            .btn-lg-wrapper {
              margin: 100px;
            }
          }
        }
      }
    }
  }
}
