@import '../../../styles/mixins.scss';

li.benefit-item {
  @include link(white);
  margin-bottom: 15px;
  position: relative;
  list-style: none; // Hacky work around for discs not appearing in iOS

  // Hacky work around for discs not appearing in iOS
  &::before {
    content: '\2022';
    position: absolute;
    left: 0;
    top: 0;
    margin-left: -1em;
  }
}
