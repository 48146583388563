.benefits-container {
  background-color: var(--black);
  padding: 45px 25px;
  margin-bottom: 30px;
  height: 100%;

  img {
    width: 100%;
  }

  ul {
    margin-bottom: 0;
  }
}

.benefit-modal-wrapper {
  padding: 15px 30px 30px;

  h3 {
    margin-top: 0;
  }

  p {
    margin-bottom: 0;
  }
}
