@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.not-found {
  width: 100%;
  height: 850px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  margin: 0px auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 120px;
  @include breakpoint-up(md) {
    padding-top: 220px;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    backdrop-filter: blur(40px);
    opacity: 0.5;
  }

  .does-not-exist-inner-container {
    height: 160px;

    opacity: 0.08;
    font-size: 154px;
    font-weight: 600;
    line-height: 154px;
    display: flex;
    justify-content: center;
    position: relative;
    background: linear-gradient(180deg, #ffffff 20.7%, rgba(255, 255, 255, 0) 82.68%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

    opacity: 0.08;

    div {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(180deg, rgba(32, 255, 255, 0.5) -3.58%, rgba(84, 0, 106, 0.5) 100%);
      opacity: 0.6;
      filter: blur(200px);
    }

    @include breakpoint-up(md) {
      height: 384px;
      font-size: 320px;
      line-height: 384px;
    }
  }

  .does-not-exist-inner-content-container {
    z-index: 2;
    width: 288px;
    height: 400px;
    background: transparent;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    align-items: center;

    margin-top: -165px;
    @include breakpoint-up(md) {
      width: 329px;
      height: 253px;
      margin-top: -275px;
    }

    .heading-text {
      display: flex;
      flex-direction: column;
      gap: 16px;

      @include breakpoint-up(md) {
        width: 329px;
      }

      .text-main {
        display: flex;
        justify-content: center;
        font-size: 22px;
        line-height: 130%;
        font-weight: 600;

        @include breakpoint-up(md) {
          font-size: 32px;
          line-height: 38.4px;
        }
      }

      .text-lead {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 16px;
        font-weight: 400;
        color: var(--gray-100);
        text-align: center;

        @include breakpoint-up(md) {
          font-size: 18px;
          line-height: 25.2px;
          font-weight: 350;
        }
      }
    }

    .text-bottom {
      font-size: 16px;
      font-weight: 600;
      line-height: 19.2px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: var(--gray-300);

      @include breakpoint-up(md) {
        font-weight: 300;
      }
    }
    .back-home-btn-container {
      width: 100%;
      margin: 0px auto;

      button {
        margin-bottom: 0px;
      }

      @include breakpoint-up(md) {
        width: fit-content;
      }
    }
  }

  .bottom-btn-container {
    display: flex;
    justify-content: space-between;
    margin-top: -8px;
    gap: 8px;
    align-items: center;
    width: 100%;

    div {
      width: 100% !important;

      button {
        width: 100%;
        margin-bottom: 0px;
      }
      a {
        margin: auto;
      }
    }
  }
}
