@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.container {
  justify-content: center;
  align-items: center;
  max-width: 1080px;
  margin: auto;
  padding: 0px 16px 56px 16px;

  @include breakpoint-down(lg) {
    max-width: 848px;
  }

  @include breakpoint-down(md) {
    max-width: 100%;
    padding: 0px 16px 56px 16px;
  }

  @include breakpoint-down(sm) {
    max-width: 100%;
    padding: 0 16px 40px 16px;
  }

  @include breakpoint-up(xxl) {
    margin-bottom: var(--spacing-6);
  }

  .iframe-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 1080px;
    width: 100%;
    @include breakpoint-down(sm) {
      height: 100%;
    }

    iframe,
    object,
    embed {
      position: absolute;
      z-index: 5;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
    }
  }
  img {
    z-index: 5;
  }
}
