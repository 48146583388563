@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.interactive-overlay-card-external {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  height: calc(100vh - 88px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: background-image 0.4s ease-in-out;

  .indicator {
    height: 6px;
    width: 6px;
    margin: 0 2px;
    background-color: transparent;
    border-radius: 50%;
    display: inline-block;
    border: 1px solid var(--gray-400);
    transition: background-color 0.6s ease;
  }

  .indicator-position {
    text-align: center;
    width: 100%;
  }

  .active-indicator {
    background-color: var(--gray-400);
  }

  @include breakpoint-up(md) {
    padding: 32px;
  }

  @include breakpoint-up(lg) {
    padding: 32px 180px;
    &:hover {
      transition: background-image 0.4s ease-in-out;
    }
  }

  .overlay-card-container {
    z-index: 0;
    display: inline-flex;
    max-width: 450px;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 16px;
    border: 1px solid;
    border-color: rgba(71, 71, 71, 0);
    background: rgba(0, 0, 0, 0.4);
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.2), 4px 4px 16px 0px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(12px);

    @include breakpoint-up(md) {
      display: flex;
      padding: 24px;
      flex-direction: column;
      align-items: center;
      gap: 40px;
    }

    .title-text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      margin: 0px;

      h2 {
        font-size: 26px;
        margin: 0px;

        .highlight-word {
          color: var(--gold);
        }
      }

      h4 {
        margin: 0px;
        color: var(--gray-200);
        line-height: 120%;
        padding-bottom: 8px;
      }

      @include breakpoint-up(md) {
        align-self: stretch;
      }
    }

    .offerings {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;

      h3 {
        letter-spacing: 2.2px;
        margin: 0px;
        text-transform: uppercase;
        line-height: 1.2;
        font-size: var(--font-size-h6-desktop);
        font-weight: var(--font-weight-bold);
        @include breakpoint-up(md) {
          letter-spacing: 2.4px;
        }
      }

      .list-items {
        ul {
          margin-top: 0px;
          padding-left: 24px;
          list-style-image: url('/assets/ph_check.png');

          li {
            &.mobile-list-item {
              display: list-item;
            }

            @media (max-width: 431px) {
              &:not(.mobile-list-item) {
                display: none;
              }
            }

            &.backgroundBlack {
              background-color: black;
              border-radius: 8px;
              font-weight: var(--font-weight-bold);
              cursor: pointer;
              padding-top: 4px;
              padding-bottom: 4px;
            }

            &.backgroundTransparent {
              background-color: transparent;
              cursor: pointer;

              &:hover {
                background-color: rgba(0, 0, 0, 0.5);
                border-radius: 8px;
              }
            }

            margin-bottom: 8px;
            flex-shrink: 0;
            font-size: 16px;
            padding-left: 8px;
            color: var(--white);
            font-weight: var(--font-weight-light);
            line-height: 150%;
          }
        }
      }
    }
  }
}
