/* 
 * @TODO: Move this css to Carousel.module.scss. 
 * Currently only works from this file.
 */
@import '../styles/variables.breakpoints.module.scss';

.slick-slider {
  position: relative;
  display: block;
  width: 100%; // for homepage carousel
  box-sizing: border-box;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0px !important;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-track {
  display: flex;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: inherit;
  min-height: 1px;

  > div {
    height: 100%;
  }
}

[dir='rtl'] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* 
 * Our custom slider css
 */
.slick-slide {
  position: relative;
}

.slide-overflow-is-visible {
  padding-top: 24px;

  .slick-list {
    overflow: visible;

    .slick-track {
      max-height: 100%;
    }
  }

  .slick-slide {
    opacity: 0.5;

    &.slick-active {
      opacity: 1;
      transition: opacity 0.3s ease;
    }
    &.slick-current {
      opacity: 1;
      transition: opacity 0.3s ease;
    }
  }
}

.slides-shown-1,
.slides-shown-2 {
  width: 85%;

  @include breakpoint-up(md) {
    width: 70%;
  }
}

.slides-shown-3 {
  width: 85%;

  .slick-slide {
    padding-right: 8px;
    padding-left: 8px;
  }
}

ul.slick-dots {
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: -60px;
  padding-left: 0;

  &.slick-top {
    top: -55px;
  }

  li button {
    height: 13px;
    width: 13px;
    padding: 0;
    background-color: transparent;
    border: 1px solid var(--white);
    border-radius: 50%;
    text-indent: -9999px;
  }
}

.is-child-of-card .slick-dots {
  bottom: 15px;
}

.slick-dots li {
  list-style: none;
  display: inline;
  margin: 0 5px;
}

.is-full-screen .slick-dots {
  right: 30px;
  top: 50%;
  width: auto;
}

.is-full-screen .slick-dots li {
  display: block;
}

.slick-dots .slick-active button {
  background: var(--white);
}

.slick-arrow {
  position: absolute;
  z-index: 999;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  text-indent: -99999px;
  width: 0;
  height: 0;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
  transition: opacity 0.25s ease-in-out;
}

.slick-arrow.slick-prev {
  left: -90px;
  border-right: 16px solid var(--white);
  border-left: none;
}

.slick-arrow.slick-next {
  right: -90px;
  border-left: 16px solid var(--white);
  border-right: none;
}

@include breakpoint-down(sm) {
  .slick-arrow.slick-prev {
    left: 10px;
  }

  .slick-arrow.slick-next {
    right: 10px;
  }
}
