@import '../../../styles/variables.breakpoints.module.scss';

.animate-canvas-container {
  /**
    To convert vh to px = (350 / 10) / 100
  */
  height: 400vh;
}

.animate-canvas-inner-container {
  position: relative;
  min-height: 100%;
}

.animate-canvas {
  height: 100vh;
  width: 100vw;
  object-fit: cover;
  position: fixed;
  bottom: 0;
}

.animate-last-image {
  height: 100vh;
  width: 100vw;
  position: absolute;
  bottom: 0;
  display: none;
  overflow: hidden;
  margin-bottom: calc(var(--footer-margin-top) * -1);

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;

    transition: all 0.5s ease;
  }
}

.animate-slider {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  padding: 0 20px 24px 20px;
  text-align: center;

  h5 {
    color: var(--white);
  }

  @include breakpoint-up(xs) {
    padding-bottom: 64px;
  }

  @include breakpoint-up(sm) {
    padding-bottom: 136px;
  }

  @include breakpoint-up(md) {
    padding-bottom: 112px;
  }

  @include breakpoint-up(lg) {
    padding-bottom: 96px;
    padding-left: 60px;
    padding-right: 60px;
  }

  @include breakpoint-up(xl) {
    padding-bottom: 112px;
    padding-left: 96px;
    padding-right: 96px;
  }
}
