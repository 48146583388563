@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.calendar {
  margin-top: var(--module-spacing);
  margin-bottom: var(--module-spacing);

  h4 {
    padding-bottom: 40px;
    color: var(--gold);
    font-weight: var(--font-weight-extra-bold);
    margin: auto 0;
    font-size: var(--font-size-body-lg);
  }

  .calendar-form-input {
    position: relative;
    border-radius: 8px;
    border: 1px solid var(--gray-700);
    background-color: var(--gray-900);
    font-weight: var(--font-weight-bold);
    width: 168px;

    @include breakpoint-up(md) {
      width: auto;
      font-size: var(--font-size-heading-sm-desktop);
      font-weight: var(--font-weight-light);
    }

    .options-toggler {
      display: flex;
      align-items: center;
      padding: 8px 48px 8px 12px;
      line-height: 100%;
      text-transform: uppercase;
      color: var(--white);
      white-space: nowrap;
    }

    .options-container {
      position: absolute;
      left: 0;
      top: 100%;
      z-index: 10;
      width: 100%;
      max-height: 400px;
      overflow: scroll;
      padding: 0;
      margin: 0;
      background-color: var(--white);

      @include breakpoint-up(md) {
        width: 180px;
      }

      ul {
        margin: 0;
        padding: 0;

        li.option {
          margin: 8px 0;
          padding: 12px 20px;
          list-style: none;
          cursor: pointer;
          color: var(--gray-700);
          font-weight: var(--font-weight-light);

          &.active,
          &:hover {
            color: var(--gray-900);
          }
        }
      }
    }
  }

  .calendar-nav {
    display: flex;
    overflow: visible;
    background-color: transparent;
    transition: background-color 0.2s ease;
    justify-content: space-between;

    @include breakpoint-up(md) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    h4 {
      color: var(--gold);
      width: auto;
      padding: 0px;
      margin: auto 0;
    }

    .price-range {
      margin-top: 20px;
      text-transform: uppercase;
      color: var(--gray-300);
      font-weight: var(--font-weight-semi-bold);
      text-align: center;
      white-space: nowrap;

      @include breakpoint-up(md) {
        margin-top: 0;
      }

      span {
        color: var(--white);
      }
    }

    .bars {
      width: 330px;
    }

    svg {
      @include svg-btn(white);
    }
  }

  .caret {
    @include arrow(up, sm);
    align-self: center;
    position: absolute;
    right: 12px;

    &.is-open {
      @include arrow(down, sm);
    }
  }

  .calendar-wrapper {
    margin-top: 16px;

    .month-label {
      background: var(--gray-800);
      border-bottom: 1px solid var(--gray-700);
      padding: 16px 20px;

      @include breakpoint-up(md) {
        padding: 24px 40px;
      }

      &:first-of-type {
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
      }

      h6 {
        margin-bottom: 0;
      }
    }

    ul {
      padding: 0px;
      margin: 0px;

      li {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 24px 0px 24px 8px;
        background-color: var(--gray-900);
        border-bottom: 1px solid var(--black);

        &:not(:last-child) {
          border-bottom: 1px solid var(--black);
        }

        .list-item-date-holder {
          display: flex;
          justify-content: left;

          .list-item__date {
            display: flex;
            align-items: center;
            line-height: var(--line-height-body);
            white-space: nowrap;
            text-transform: uppercase;

            span {
              color: var(--white);

              &.list-item__formatted-date {
                font-weight: var(--font-weight-extra-bold);
                color: var(--gray-100);
              }

              &.list-item__separator,
              &.list-item__formatted-day {
                font-weight: var(--font-weight-light);
              }

              &.list-item__separator {
                padding: 0 8px;
              }
            }
          }

          p.price {
            color: var(--gray-300);
            min-width: 250px;
            margin-bottom: 8px;

            span {
              font-weight: var(--font-weight-extra-bold);
            }
          }
        }

        .list-item-ctas {
          flex: 1 1;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          grid-gap: 8px;
          gap: 8px;

          @include breakpoint-up(md) {
            padding-left: 40px;
            justify-content: flex-end;
            grid-gap: 16px;
            gap: 16px;
          }
        }
      }
    }
  }

  .load-more {
    display: flex;
    list-style: none;
    justify-content: center;
    padding: 0px;
    margin-top: 40px;
  }
}
