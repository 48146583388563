@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.disclaimer {
  color: var(--gray-500);
  font-size: var(--font-size-body-xs);
  font-weight: var(--font-weight-light);
  box-sizing: border-box;
  position: relative;
  position: absolute;
  display: flex;
  justify-content: center;
  height: 80px;
  width: 100%;
  padding-top: 30px;

  .ticketmaster-icon {
    display: inline;
    margin-left: 5px;
  }
}

.footer-global {
  margin: var(--footer-margin-top) 8px 0 8px;
  padding-top: 72px;
  padding-bottom: 32px;
  border-top: 1px solid var(--gray-700);

  a {
    @include link(white);
  }

  .column__header {
    color: var(--gray-300);
    font-weight: var(--font-weight-extra-bold);
  }

  .column-1 {
    .column__header {
      text-transform: uppercase;
      text-align: center;

      @include breakpoint-up(lg) {
        text-align: left;
      }
    }
  }

  .column__content {
    @include body-copy(sm);
  }

  ul {
    padding-left: 0;
    margin: 0;

    li {
      list-style: none;
    }
  }

  svg {
    width: 20px;
    height: 20px;
    margin-right: 16px;
  }

  .row {
    max-width: 300px;
    margin-right: auto;
    margin-left: auto;

    @include breakpoint-up(md) {
      max-width: none;
    }

    @include breakpoint-up(lg) {
      @include flex-row();
    }
  }

  .column-2 {
    margin-top: 54px;

    @include breakpoint-up(lg) {
      margin-top: 0;
      margin-left: auto;
    }

    .column-2__row {
      @include breakpoint-up(md) {
        @include flex-row();
      }

      .column-2__column {
        margin-bottom: 54px;

        @include breakpoint-up(md) {
          @include flex-col();
        }

        @include breakpoint-up(md) {
          margin-bottom: 0;
        }

        li {
          margin-bottom: 16px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .footer-global__bottom {
    border-top: 1px solid var(--gray-500);
    padding-top: 40px;
    margin-top: 40px;

    @include breakpoint-up(lg) {
      @include flex-row(24px);
    }

    .bottom__logos,
    .bottom__nav {
      @include breakpoint-up(lg) {
        @include flex-col();
      }
    }

    .bottom__logos {
      margin-bottom: 24px;

      @include breakpoint-up(lg) {
        margin-bottom: 0;
      }

      .logos-row {
        justify-content: center;

        @include breakpoint-up(md) {
          @include flex-row(24px);
        }

        @include breakpoint-up(lg) {
          justify-content: flex-start;
        }

        .logo {
          width: var(--footer-logo-width);
          max-width: var(--footer-logo-width);
          height: var(--footer-logo-height);
          max-height: var(--footer-logo-height);
          margin: 0 auto 16px auto;

          @include breakpoint-up(md) {
            @include flex-col();
            margin: 0;
          }
        }
      }
    }

    .bottom__nav {
      ul {
        text-align: center;

        @include breakpoint-up(lg) {
          text-align: right;
        }

        li {
          display: inline-block;
          margin-right: 24px;

          &:last-child {
            margin-right: 0;
          }

          img {
            padding-left: 5px;
          }

          a {
            @include body-copy(xsm);
            @include link(gray-500);
          }
        }
      }

      small {
        color: var(--gray-500);
      }
    }
  }
  .footer-global__bottom_partnerships {
    border-top: 1px solid var(--gray-700);
    padding-top: 40px;
    margin-top: 40px;
  }
}
