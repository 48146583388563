.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 10px;
  align-items: center;
  width: 100%;
}

.heading {
  width: 100%;
}
