@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.card-overlay-container {
  padding-top: var(--module-spacing);
  padding-bottom: var(--module-spacing);

  .col-wrapper--media {
    margin-bottom: var(--spacing-1);
    border-radius: 16px;

    @include breakpoint-up(md) {
      margin-bottom: 0px;
    }

    &:hover {
      background: rgba(var(--black-rgb), 0.5);
    }
  }

  .card-overlay {
    @include breakpoint-up(xxl) {
      margin-bottom: var(--spacing-6);
    }
  }
}
