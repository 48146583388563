@import '../../../styles/variables.breakpoints.module.scss';

// .benefits-card-icon-container styles had to be added to globals.scss

.benefits-card-container {
  margin-bottom: var(--spacing-2);

  @include breakpoint-up(xl) {
    &:nth-child(4),
    &:nth-child(5) {
      margin-bottom: 0;
    }
  }

  @include breakpoint-down(lg) {
    &:last-child {
      margin-bottom: 0;
    }
  }

  .benefits-card-inner {
    padding: 20px;
    height: 100%;
    background-color: var(--gray-900);

    @include breakpoint-up(md) {
      padding: 45px 25px;
    }

    img {
      width: 100%;
    }

    h3 {
      margin-bottom: 0;
      margin-top: 0;
    }

    ul {
      margin-bottom: 0;
    }
  }
}
