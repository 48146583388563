@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.iframe-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  width: 100%;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.poster-container {
  position: relative;
  width: 100%;

  .modal-open-button {
    width: 64px;
    height: 64px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--black);
    border-radius: 50%;
    border: 2px solid var(--brand-secondary);
    transition: opacity 0.3s;

    .play-icon {
      @include arrow(right);
    }
  }

  &:hover {
    .modal-open-button {
      opacity: 0.85;
    }
  }
}
