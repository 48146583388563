@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.infobar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin: auto;
  max-width: 1080px;
  padding: 40px 141px;

  @include breakpoint-down('md') {
    display: flex;
    padding: 0px 24px;
    justify-content: space-between;
    align-items: center;
    max-width: unset;
  }
  @include breakpoint-down('sm') {
    display: flex;
    padding: 0px 24px;
    gap: 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: unset;
  }

  h1 {
    text-align: center;
    margin-top: 16px;
    padding: 0px;
    .highlight-word {
      color: var(--turquoise-500);
    }
    font-size: var(--font-size-h2-desktop);
    font-weight: var(--font-weight-bold);
  }
  .show-info {
    display: flex;
    justify-content: space-between;

    &.justify-around {
      justify-content: space-around;
    }
  }
  .vertical-line {
    border-right: 1px solid var(--gray-300);
  }
  .horizontal-line {
    border-bottom: 1px solid var(--gray-300);
    width: 100%;
  }
  .margin-auto {
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
    margin-bottom: 40px;
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .container {
    display: grid;
    place-items: center;
  }
  .container-padding {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.gradient-height {
  height: 120px;
}
.linear-gradient-bottom-gold {
  background: var(--gold-bottom-gradient-bg-color);
}
.linear-gradient-top-gold {
  background: var(--gold-top-gradient-bg-color);
}
.linear-gradient-bottom-blue {
  background: var(--blue-bottom-gradient-bg-color);
}
.linear-gradient-top-blue {
  background: var(--blue-top-gradient-bg-color);
}

.infobar > div {
  width: 100%;
}
