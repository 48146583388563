@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.hero-container {
  // will appear in front of modal without position and z-index
  position: relative;
  z-index: 5;

  .breadcrumb-container {
    position: absolute;
    z-index: 100;
    padding: 80px 23px;
  }

  &.has-logo {
    .hero {
      margin-bottom: 40px !important;

      @include breakpoint-up(md) {
        margin-bottom: 64px !important;
      }
    }

    p {
      text-align: left !important;
      font-size: 16px !important;

      @include breakpoint-up(md) {
        font-size: 18px !important;
      }
    }

    .title-with-logo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1080px;

      h1 {
        text-align: left;
        font-size: var(--font-size-h1-mobile) !important;

        @include breakpoint-up(md) {
          font-size: var(--font-size-h1-desktop) !important;
        }
      }
    }
  }

  h2 {
    @include heading(2);
  }

  &.has-single-heading {
    text-align: center;

    .hero {
      padding-top: 100px;
      margin-bottom: 70px;

      @include breakpoint-up(xs) {
        padding-top: 135px;
        margin-bottom: 85px;
      }

      @include breakpoint-up(sm) {
        padding-top: 160px;
        margin-bottom: 150px;
      }

      @include breakpoint-up(md) {
        padding-top: 180px;
      }

      @include breakpoint-up(lg) {
        margin-bottom: 250px;
      }

      @include breakpoint-up(xxl) {
        margin-bottom: 460px;
      }
    }

    .email-signup-page {
      padding-top: 100px;
    }

    .hero-container__bg-img {
      height: 780px;

      @include breakpoint-up(xs) {
        height: 980px;
      }

      @include breakpoint-up(sm) {
        height: 780px;
      }

      @include breakpoint-up(md) {
        height: 980px;
      }

      @include breakpoint-up(lg) {
        height: 1080px;
      }

      @include breakpoint-up(xl) {
        height: 980px;
      }

      @include breakpoint-up(xxl) {
        height: 1320px;
      }
    }

    h1 {
      @include heading(display);
      margin-bottom: 0;

      @include breakpoint-up(xl) {
        padding-left: 40px;
        padding-right: 40px;
      }

      @include breakpoint-up(xxl) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    p {
      @include body-copy('lg');
      margin: 24px -1px;
      text-align: center;
    }

    .description-container {
      margin-top: -10px;
    }
  }

  .subheadline {
    color: var(--gray-300);
  }

  // aligns content to bottom of hero
  &.has-details-and-subhead {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 245px;
    padding-bottom: var(--spacing-3);

    @include breakpoint-up(sm) {
      padding-top: 300px;
    }

    @include breakpoint-up(md) {
      padding-top: 350px;
    }

    @include breakpoint-up(lg) {
      padding-top: 450px;
    }

    @include breakpoint-up(xl) {
      padding-top: 575px;
    }

    @include breakpoint-up(xxl) {
      padding-top: 725px;
    }

    .hero {
      background: linear-gradient(transparent 0%, black 20%); // TODO: add to variables if needed
    }

    .hero-container__bg-img {
      top: 40px;
      height: 300px;

      @include breakpoint-up(sm) {
        height: 350px;
      }

      @include breakpoint-up(sm) {
        height: 400px;
      }

      @include breakpoint-up(xl) {
        height: 800px;
      }

      @include breakpoint-up(xxl) {
        top: 0;
        height: 800px;
      }
    }

    h1 {
      @include heading(1);
      margin-bottom: 15px;

      @include breakpoint-up(xxl) {
        margin-top: 30px;
        margin-bottom: 30px;
      }
    }

    p {
      @include body-copy(md);
    }
  }

  &.is-short {
    .hero-container__bg-img {
      height: 250px;

      @include breakpoint-up(lg) {
        height: 340px;
      }

      @include breakpoint-up(xl) {
        height: 450px;
      }
    }

    .hero {
      margin-bottom: 60px;
      padding-top: 115px;

      @include breakpoint-up(xs) {
        margin-bottom: 70px;
        padding-top: 170px;
      }

      @include breakpoint-up(sm) {
        padding-top: 200px;
      }

      @include breakpoint-up(md) {
        margin-bottom: 100px;
        padding-top: 180px;
      }

      @include breakpoint-up(lg) {
        padding-top: 180px;
      }
    }
  }

  &.has-icims-career-form {
    .hero-container__bg-img {
      min-height: 350px;
    }

    .hero {
      margin-bottom: 60px;

      @include breakpoint-up(lg) {
        margin-bottom: 100px;
      }
    }
  }

  .hero {
    width: 100%;
    position: relative;
    z-index: inherit;
  }

  .hero-container__bg-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    background-repeat: no-repeat;
    background-position: top center;
    background-attachment: scroll;
    background-size: cover;
    background-color: var(--black);

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 90px;
      min-width: 100%;
      background: var(--hero-fade-bottom-color);
    }
  }

  button.read-or-hide {
    display: flex;
    align-items: center;
    padding: 0;
    margin-top: 7px;
    background-color: transparent;
    border: none;
    color: var(--white);
    text-transform: uppercase;
    outline: none;

    .caret {
      @include arrow(up, sm);
      align-self: center;
      margin-left: 12px;

      &.is-open {
        @include arrow(down, sm);
      }
    }
  }

  // .details-container is rendered outside of .hero for mobile
  .details-container {
    @include breakpoint-down(sm) {
      margin-top: var(--spacing-2);
    }
  }

  .read-more-content {
    margin-top: 10px;

    ul {
      li {
        margin-bottom: 10px;
      }
    }
  }
}
