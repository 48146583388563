@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.disclaimer-container {
  width: 100%;
  margin-top: 15px;
  width: 488px;
  padding: 0 !important;
  gap: 16px;
  border-radius: 8px 8px 0px 0px;

  @include breakpoint-up(md) {
    padding: 8px 16px 8px 16px;
    margin-top: 10px;
  }

  .sticky-disclaimer {
    position: fixed;
    bottom: 0px;
    color: white;
    padding: 8px 16px 8px 16px;
    z-index: 1000;
    font-size: 14px;
    font-weight: 350;
    line-height: 21px;
    text-align: left;
    background: #303030cc;

    @include breakpoint-up(md) {
      left: 50%;
      transform: translateX(-50%);
    }

    p {
      margin: 0;
      margin-right: 30px;
    }
  }

  svg {
    @include svg-btn(#ababab);
    position: absolute;
    align-items: center;
    right: 15px;
    width: 12px;
    color: #ababab;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;

    @include breakpoint-up(md) {
      width: 12px;
    }

    @include breakpoint-up(lg) {
      right: 10px;
      width: 12px;
    }
  }

  .sticky-disclaimer-button {
    display: inline;
    background: #303030cc;
    color: white;
  }
}
