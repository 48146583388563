@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.classification-container {
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 120px 16px;
  justify-content: center;
  align-items: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @include breakpoint-up(md) {
    padding: 120px 80px;
  }

  blockquote {
    margin: 0px;
  }

  h4 {
    color: var(--white) !important;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;

    @include breakpoint-up(md) {
      font-size: 56px;
    }
  }
  h2 {
    color: var(--gold);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    margin-bottom: 8px;
  }
  p {
    color: var(--white);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 350;
    line-height: 100%;
  }
}
