.disclaimer-container {
  width: 100%;
  margin-top: var(--module-spacing);
  margin-bottom: var(--module-spacing);

  .disclaimer {
    p {
      color: var(--gray-300);
      font-weight: var(--font-weight-light);
      font-size: var(--font-size-body-sm);
    }
  }
}
