@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.carousel-container {
  display: flex;
  margin-bottom: var(--module-spacing);
  margin-top: var(--module-spacing);

  .nav-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
    width: 100%;
  }

  h3 {
    margin-top: 0px;
    margin-bottom: 8px;
    font-size: var(--font-size-h2-mobile);
    font-weight: var(--font-weight-bold);
    line-height: 1;

    @include breakpoint-up(md) {
      margin-bottom: 16px;
      font-size: var(--font-size-h2-desktop);
    }
  }

  p {
    font-size: var(--font-size-h3-mobile);

    @include breakpoint-up(md) {
      font-size: var(--font-size-h3-desktop);
      margin: 40px 0 16px;
    }
  }

  .count-container {
    p {
      @include body-copy(xs);
      margin-bottom: 0;
    }
  }

  .slider-nav-container {
    position: relative;
    display: flex;
    gap: 16px;

    .slick-arrow-custom {
      background-color: var(--gray-800);
      border-radius: 50%;
      cursor: pointer;
      height: 48px;
      padding: 12px;
      width: 48px;
    }

    .disabled-nav {
      color: var(--gray-600);
    }

    svg {
      height: 24px;
      width: 24px;
      &:focus {
        color: var(--white);
      }
    }
  }
}

.edp-experience {
  padding: 0 0;
}
