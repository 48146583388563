@import '../../../../styles/variables.breakpoints.module.scss';
@import '../../../../styles/mixins.scss';

.hero-container {
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &.hero-container__background-height--third {
    padding-top: 33vh;
  }

  &.hero-container__background-height--half {
    padding-top: 50vh;
  }

  &.hero-container__background-height--full {
    height: 100vh;
  }

  .header-title-container {
    display: flex;
    flex-direction: column;

    .title-container-headline {
      margin-bottom: 8px;
    }

    .title-container-eyebrow {
      margin-bottom: 8px;
    }
  }

  .btn-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto 0px;
    gap: 8px;

    button,
    a {
      width: 100%;
    }

    .small-buttons-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 8px;
    }

    .small-buttons-container button,
    .small-buttons-container a {
      padding: 8px;
      text-align: center;
      box-sizing: border-box;
    }

    .two-or-fewer {
      margin-top: 0;
    }
  }

  .description-container {
    padding: 12px 0px;
    margin: 0px;
    font-size: 14px;
    font-weight: 350;

    p {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.has-details {
    display: flex;
    flex-direction: column;
    flex: 1;

    .hero-container__background-height--third {
      height: 33vh;
    }

    .hero-container__background-height--half {
      height: 50vh;
    }

    .hero-container__background-height--full {
      height: 100vh;
    }

    .hero-container__bg-video {
      width: 100%;
      overflow: hidden;

      video {
        display: block;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    h1 {
      font-size: 24px;
      font-weight: 350;
      margin-bottom: 8px;
      width: auto;
    }

    h2 {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 8px;
      margin-top: 8px;
    }
  }

  .hero-container__bg-video {
    min-height: 350px;
  }

  .hero {
    width: 100%;
    position: relative;
    z-index: inherit;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 47.56%);
  }

  .hero-container__bg-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    background-repeat: no-repeat;
    background-position: top center;
  }

  .hero-container__bg-video {
    position: absolute;
    top: 0;
  }
}
