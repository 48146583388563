@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.voting-button {
  overflow: hidden;
  position: relative;
  padding: 0;
  border: none;
  border-radius: 8px;
  height: 88px;

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .gradient-layer {
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(247.63deg, rgba(5, 195, 221, 0.3) 27.85%, rgba(32, 255, 255, 0.3) 81.23%);

    &:hover {
      background: linear-gradient(247.63deg, rgba(5, 195, 221, 0.45) 27.85%, rgba(32, 255, 255, 0.45) 81.23%);
    }
  }

  .opacity-layer {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: var(--black);
    opacity: 60%;
    cursor: pointer;

    &:hover {
      opacity: 50%;
    }
  }

  span {
    color: var(--white);
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 100%;
  }

  &:hover,
  &:focus,
  &:focus-within {
    border: none;
    .gradient-layer {
      background: linear-gradient(247.63deg, rgba(5, 195, 221, 0.45) 27.85%, rgba(32, 255, 255, 0.45) 81.23%);
    }
    .opacity-layer {
      opacity: 50%;
    }
  }

  @include breakpoint-up(md) {
    span {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      cursor: pointer;
    }
  }
}