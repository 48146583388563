@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.header-global {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9000;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 16px;
  overflow: visible;
  transition: background-color 0.2s ease;
  justify-content: space-between;

  .header-global__wrapper {
    display: flex;
    justify-content: space-between;
  }

  li {
    list-style: none;

    a,
    span {
      font-weight: var(--font-weight-light);
    }
  }

  .brand-logo {
    display: flex;
    align-items: center;
    width: var(--header-brand-logo-width-mobile);
    min-width: var(--header-brand-logo-width-mobile);
    height: var(--header-brand-logo-height-mobile);
    min-height: var(--header-brand-logo-height-mobile);
    margin-right: 24px;

    @include breakpoint-up(md) {
      width: var(--header-brand-logo-width-desktop);
      min-width: var(--header-brand-logo-width-desktop);
      height: var(--header-brand-logo-height-desktop);
      min-height: var(--header-brand-logo-height-desktop);
    }

    a {
      display: flex;
    }
  }

  &.header-global--desktop {
    height: var(--header-height-desktop);
    padding-top: 0;

    .header-global__column {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.header-global--mobile {
  height: var(--header-height-mobile);

  .header-global__column {
    display: flex;
  }

  .brand-logo {
    margin-right: auto;
  }
}
