@import '../../../styles/variables.breakpoints.module.scss';

.btn-group-container {
  border: 2px solid var(--gray-500);
  border-left: none;
  border-right: none;
  margin-bottom: 40px;
  margin-top: 33px;

  &.no-bottom-divider {
    border-bottom: none;
  }

  p {
    margin-top: 32px;
    text-align: center;
    color: var(--gray-300);
  }

  .button-group {
    margin-bottom: 32px;

    @include breakpoint-up(md) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    div:not(:first-child) {
      margin-top: 16px;

      @include breakpoint-up(md) {
        margin-top: 0;
        margin-left: 16px;
      }
    }

    .button-group__button {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
