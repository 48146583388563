@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.infobar-section {
  display: flex;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-right: auto;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  max-width: 1080;

  h2 {
    font-weight: var(--font-weight-extra-bold);
    font-size: var(--font-size-h1-mobile);
    //SEO optimization stylings below, hence why not using h3 per SPH-1195
    margin-top: 15px !important;
    margin-bottom: 0 !important;
    text-align: left !important;
  }
  p {
    color: var(--white);
    font-weight: var(--font-weight-light);
    font-size: var(--font-size-body-sm);
    line-height: 1.5;
    @include breakpoint-up(lg) {
      font-size: var(--font-size-body-md);
      line-height: 24px;
    }
  }
  h5 {
    color: var(--color-neutral-white);
    letter-spacing: 2.4px;
    text-transform: uppercase;
    line-height: 100%;
    margin-bottom: 0;
  }
  @include breakpoint-down('sm') {
    display: block;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    padding: 24px 0px;
    margin: 0px;
  }
  .image-container {
    padding-bottom: 24px;

    @include breakpoint-down('sm') {
      flex-shrink: 0;
      width: 100%;
      justify-content: center;
      display: flex;
    }
  }
}
.infobar-section > div {
  padding-right: 0 !important;
}

.infobar-description {
  ul {
    list-style-image: url('/assets/blue-check-mark.png');
    padding-left: 24px;
    margin-top: 8px;
    margin-bottom: 0;

    li {
      margin-bottom: 8px;
      font-size: 14px;
      padding-left: 8px;
    }
  }
}
