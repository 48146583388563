@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

$video-offset-top: 75px;
$headline-height-desktop: 45px;
$headline-height-mobile: 32px;

.hero-full-screen-video {
  position: relative;
  width: 100%;
  height: calc(100vh - $video-offset-top);
  min-height: calc(100vh - $video-offset-top);
  overflow: hidden;
  margin-bottom: calc(var(--footer-margin-top) * -1);
  margin-top: 0;

  transition: all 0.5 ease;

  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: var(--z-index-1);

  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .video-without-pointer-event {
    pointer-events: none;
  }

  .poster-container {
    position: relative;
    width: 100%;
    height: calc(100vh - $video-offset-top);
    min-height: calc(100vh - $video-offset-top);
    overflow: hidden;
    margin-top: 0;
    img {
      object-fit: cover;
      width: 100%;
      position: relative;
      height: 100%;
    }
  }

  .content {
    position: absolute;
    top: 50px;
    width: 100%;
    height: 100%;
    text-align: center;
    z-index: var(--z-index-101);

    .brand-logo-xl {
      .image-container {
        position: relative;
        max-width: 600px;
        margin: auto;

        img {
          object-fit: contain;
          width: 100%;
          position: relative;
          height: unset;
        }
      }
    }

    .cta-container {
      position: absolute;
      bottom: 150px;
      right: 0;
      width: 100%;
      text-align: center;
    }

    .brand-headline-group {
      position: relative;
      padding-top: $headline-height-mobile;
      margin-left: auto;
      margin-right: auto;

      @include breakpoint-up(sm) {
        padding-top: $headline-height-desktop;
      }

      .headline {
        position: absolute;
        top: -7px;
        display: flex;
        align-items: center;
        width: 100%;
        height: $headline-height-mobile;
        opacity: 0;
        transition: opacity 0.5s ease-in-out;

        &.show {
          opacity: 1;
        }

        @include breakpoint-up(sm) {
          height: $headline-height-desktop;
        }
      }
    }
  }

  .container {
    height: 100%;
    z-index: var(--z-index-1);
  }

  .volume-toggle-container {
    position: absolute;
    bottom: 60px;
    right: 0;
    width: 100%;
    margin-top: 0;

    @include breakpoint-down(xs) {
      position: static;
      margin-top: 13px;
      display: inline-block;
    }

    .volume-toggle {
      width: 56px;
      height: 56px;
      margin: 0 auto;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .is-visible {
    visibility: visible;
    opacity: 1;
    transition: 0.6s ease-in-out;
    transition-delay: 0.25s;
    z-index: var(--z-index-100);
  }

  .is-hidden {
    visibility: hidden;
    opacity: 0;
    transition: 0.6s ease-in-out;
    transition-delay: 0.25s;
  }
}
