@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

button.read-or-hide {
  display: flex;
  align-items: center;
  padding: 0;
  margin-top: 8px;
  background-color: transparent;
  border: none;
  font-size: var(--font-size-body-sm);
  color: var(--brand-primary);
  outline: none;

  .caret {
    @include arrow(up, sm);
    align-self: center;
    margin-left: 8px;
    border-color: transparent transparent var(--brand-primary) transparent;

    &.is-open {
      @include arrow(down, sm);
      border-color: transparent transparent var(--brand-primary) transparent;
    }
  }
}
