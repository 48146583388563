@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.hero-container {
  // will appear in front of modal without position and z-index
  position: relative;
  z-index: 5;

  .header-title-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .btn-container {
    display: block;
    width: 100%;
    margin: auto 0px;
    @include breakpoint-up(md) {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;
      width: auto;
      margin: 0px;
    }

    @include breakpoint-up(lg) {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 16px;
    }

    button,
    a {
      margin: auto 0px auto;
      width: 100%;
      &:first-child {
        margin-bottom: 8px;
        @include breakpoint-up(md) {
          margin: auto 0px auto;
        }
      }
      @include breakpoint-up(md) {
        width: 100%;
      }
    }
  }
  .show-info {
    margin-top: 26px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    gap: 24px;

    @include breakpoint-up(md) {
      display: flex;
      justify-content: space-between;
    }

    .image-holder {
      display: flex;
      align-items: center;

      &:first-child {
        grid-row: 1;
        width: 100%;
        grid-column: 1 / 3;
        justify-content: center;
        @include breakpoint-up(md) {
          width: auto;
        }
      }
      justify-content: center;
      align-items: center;
      width: 100%;
      grid-row: 2;

      @include breakpoint-up(md) {
        justify-content: flex-start;
        align-items: flex-start;
        width: auto;
      }
      span {
        margin-left: 8px;
      }
    }
  }
  &.has-single-heading {
    text-align: center;

    .hero {
      padding-top: 100px;
      margin-bottom: 70px;

      @include breakpoint-up(xs) {
        padding-top: 135px;
        margin-bottom: 85px;
      }

      @include breakpoint-up(sm) {
        padding-top: 160px;
        margin-bottom: 150px;
      }

      @include breakpoint-up(md) {
        padding-top: 180px;
      }

      @include breakpoint-up(lg) {
        margin-bottom: 250px;
      }

      @include breakpoint-up(xxl) {
        margin-bottom: 460px;
      }
    }

    .hero-container__bg-img {
      height: 780px;

      @include breakpoint-up(xs) {
        height: 980px;
      }

      @include breakpoint-up(sm) {
        height: 780px;
      }

      @include breakpoint-up(md) {
        height: 980px;
      }

      @include breakpoint-up(lg) {
        height: 1080px;
      }

      @include breakpoint-up(xl) {
        height: 980px;
      }

      @include breakpoint-up(xxl) {
        height: 1320px;
      }
    }

    .hero-container__bg-video {
      height: 780px;
      width: 100%;
      @include breakpoint-up(xs) {
        height: 980px;
      }

      @include breakpoint-up(sm) {
        height: 780px;
      }

      @include breakpoint-up(md) {
        height: 980px;
      }

      @include breakpoint-up(lg) {
        height: 1080px;
      }

      @include breakpoint-up(xl) {
        height: 980px;
      }

      @include breakpoint-up(xxl) {
        height: 1320px;
      }
    }

    h1 {
      @include heading(display);
      margin-bottom: 0;

      @include breakpoint-up(xl) {
        padding-left: 40px;
        padding-right: 40px;
      }

      @include breakpoint-up(xxl) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    p {
      @include body-copy('lg');
      margin: var(--spacing-1) auto 0 auto;
      text-align: center;
    }
  }

  .description-container {
    margin-top: 12px;
    @include breakpoint-up(md) {
      margin: 0px;
    }
  }

  .read-or-hide {
    display: flex;
    align-items: center;
    padding: 0;
    background-color: transparent;
    border: none;
    font-size: var(--font-size-body-sm);
    color: var(--brand-primary);
    outline: none;
    .caret {
      @include arrow(up, sm);
      align-self: center;
      margin-left: 8px;
      border-color: transparent transparent var(--brand-primary) transparent;
      &.is-open {
        @include arrow(down, sm);
        border-color: transparent transparent var(--brand-primary) transparent;
      }
    }
  }

  .subHeadline {
    font-weight: var(--font-weight-extra-bold);
    color: var(--gold-500);
    margin: 0px;
    line-height: var(--line-height-sub-headline);
  }

  // aligns content to bottom of hero
  &.has-details-and-subhead {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 245px;

    @include breakpoint-up(sm) {
      padding-top: 350px;
    }

    @include breakpoint-up(md) {
      padding-top: 450px;
    }

    @include breakpoint-up(lg) {
      padding-top: 580px;
    }

    @include breakpoint-up(xl) {
      padding-top: 575px;
    }

    @include breakpoint-up(xxl) {
      padding-top: 725px;
    }

    .hero {
      background: linear-gradient(transparent 0%, black 20%); // TODO: add to variables if needed
    }

    .hero-container__bg-img {
      top: 40px;
      height: 300px;

      @include breakpoint-up(sm) {
        height: 350px;
      }

      @include breakpoint-up(sm) {
        height: 528px;
      }

      @include breakpoint-up(xl) {
        height: 800px;
      }

      @include breakpoint-up(xxl) {
        top: 0;
        height: 800px;
      }
    }

    .hero-container__bg-video {
      height: 300px;
      width: 100%;
      overflow: hidden;

      video {
        display: block;
        object-fit: cover;
        padding-top: 72px;
        width: 100%;
      }
      @include breakpoint-up(sm) {
        height: 350px;
      }

      @include breakpoint-up(sm) {
        height: 550px;
      }

      @include breakpoint-up(xl) {
        height: 800px;
      }

      @include breakpoint-up(xxl) {
        top: 0;
        height: 800px;
      }
    }

    h1 {
      @include heading(1);
      margin: 0;
      width: auto;

      @include breakpoint-up(lg) {
        font-size: var(--font-size-h1-desktop);
      }

      @include breakpoint-up(md) {
        font-size: var(--font-size-h1-mobile);
      }
    }

    p {
      @include body-copy(md);
      line-height: var(--line-height-paragraph);
      margin-bottom: 12px;

      @include breakpoint-up(md) {
        margin-bottom: 15px;
      }
    }
  }

  &.is-short {
    .hero-container__bg-img {
      height: 250px;

      @include breakpoint-up(lg) {
        height: 340px;
      }

      @include breakpoint-up(xl) {
        height: 450px;
      }
    }

    .hero-container__bg-video {
      height: 250px;

      @include breakpoint-up(lg) {
        height: 340px;
      }

      @include breakpoint-up(xl) {
        height: 450px;
      }
    }

    .hero {
      margin-bottom: 60px;
      padding-top: 115px;

      @include breakpoint-up(xs) {
        margin-bottom: 70px;
        padding-top: 170px;
      }

      @include breakpoint-up(sm) {
        padding-top: 200px;
      }

      @include breakpoint-up(md) {
        margin-bottom: 100px;
        padding-top: 180px;
      }

      @include breakpoint-up(lg) {
        padding-top: 180px;
      }
    }
  }

  &.has-icims-career-form {
    .hero-container__bg-img {
      min-height: 350px;
    }

    .hero-container__bg-video {
      min-height: 350px;
    }

    .hero {
      margin-bottom: 60px;

      @include breakpoint-up(lg) {
        margin-bottom: 100px;
      }
    }
  }

  .hero {
    width: 100%;
    position: relative;
    z-index: inherit;
  }

  .hero-container__bg-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    background-repeat: no-repeat;
    background-position: top center;
    background-attachment: scroll;
    background-size: cover;
    background-color: var(--black);

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 90px;
      min-width: 100%;
      background: var(--hero-fade-bottom-color);
    }
  }

  .hero-container__bg-video {
    position: absolute;
    top: 0;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 90px;
      min-width: 100%;
      background: var(--hero-fade-bottom-color);
    }
  }

  // .details-container is rendered outside of .hero for mobile
  .details-container {
    @include breakpoint-down(sm) {
      margin-top: var(--spacing-2);
    }
  }
}

.edp-video-container {
  video {
    width: 100%;
    height: 100%;
    border-radius: 16px;
  }
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
