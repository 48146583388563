.press-link {
  width: 100%;
  border-bottom: 1px solid var(--gray-900);
  padding-bottom: 7px;
  margin-bottom: 20px;

  h4 + h4 {
    margin-top: 5px;
  }

  p {
    margin-bottom: 0;

    &:last-of-type {
      margin-top: 10px;
    }
  }
}
