@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';
.slide {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: all 0.5 ease;
  padding-right: 8px;
  padding-left: 8px;

  &.slide--full-screen {
    display: flex;
    align-items: center;
    width: 100vw;
    height: 100vh;
  }

  .image-container {
    width: 100%;
    height: 100%;
    position: relative;
    max-height: unset;
    height: auto;

    @include breakpoint-up(md) {
      max-height: 520px;
      height: 520px;
    }
  }

  h4 {
    margin-top: 16px;
  }

  img {
    margin-bottom: 16px;
    border-radius: 8px;
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: relative;
    @include breakpoint-up(md) {
      width: 100%;
    }
  }

  p {
    margin-top: 8px;
    font-size: var(--font-size-body-sm);
    font-weight: var(--font-weight-light);
    line-height: var(--line-height-body);
    text-align: center;
  }
}

.is-full-width {
  width: 100%;
  max-width: 100% !important;
}
