@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.modal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(var(--black-rgb), 0.5);

  svg {
    @include svg-btn(grey);
    color: #c6c6c6;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  .modal-dialog {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
  }

  .modal-content {
    position: relative;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.8);
    overflow: none;
    width: 288px;
    height: 480px;
    padding: 24px 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;

    @include breakpoint-up(md) {
      width: 700px;
      height: 550px;
      padding: 24px 24px 32px 24px;
    }

    @media screen and (max-width: 844px) and (orientation: landscape) {
      width: 100%;
      height: auto;
      padding: 16px;
      border-radius: 0;
      justify-content: center;
      overflow-y: auto;
      background-size: cover;
      background-position: center;
    }

    .statusbar-container {
      display: flex;
      justify-content: center;
    }

    .statusbar {
      display: inline-flex;
      padding: 4px 8px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 4px;
      backdrop-filter: blur(5px);

      p {
        color: var(--white);
        text-align: center;
        font-family: Graphie;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        letter-spacing: 2px;
        margin-bottom: 0;
      }
    }

    .statusbar-color-gold {
      background-color: var(--gold);
    }

    .statusbar-color-orange {
      background-color: var(--orange);
    }

    .statusbar-color-purple {
      background-color: var(--purple);
    }

    .statusbar-color-blue {
      background-color: var(--turquoise-600);
    }

    h2 {
      margin-top: 4px;
      margin-bottom: 4px;
      line-height: 120%;
      font-weight: 400;

      @include breakpoint-up(md) {
        font-size: 32px;
        margin-top: 8px;
        margin-bottom: 8px;
      }
    }

    .description-container {
      margin-bottom: 16px;
      align-items: center;

      @include breakpoint-up(md) {
        margin-bottom: 24px;
      }

      p {
        line-height: 120%;
        letter-spacing: 0.8px;
        font-weight: 350;
        margin-bottom: 0;
      }
    }

    .btn-container {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;
      margin-bottom: 8px;

      @include breakpoint-up(md) {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        margin-bottom: 16px;
      }

      button,
      a {
        margin: 0;
        width: 100%;
      }
    }

    .disclaimer-container {
      align-self: stretch;

      p {
        color: var(--gray-400);
        text-align: center;
        font-size: 12px;
        font-weight: 350;
        line-height: 150%;
        margin-bottom: 0;
      }
    }
  }
}
