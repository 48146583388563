@import '../../../styles/mixins.scss';
@import '../../../styles/variables.breakpoints.module.scss';

.leaderboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
  gap: 16px;

  @include breakpoint-up(md) {
    margin-top: 16px;
  }

  @include breakpoint-up(lg) {
    gap: 24px;
    margin-top: 32px;
  }

  .details-container {
    h3 {
      font-size: 16px;
      font-weight: 400;

      @include breakpoint-up(md) {
        font-size: 22px;
        font-weight: 350;
      }
    }

    h5,
    p {
      font-size: 12px;
      font-weight: 350;

      @include breakpoint-up(md) {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }

  ul {
    margin: 0px auto;
    position: static !important;
  }

  h2 {
    font-size: 16px;
    font-weight: 350;
    line-height: 120%;
    margin: 0;

    @include breakpoint-up(md) {
      font-size: 24px;
    }
  }
  .school-name {
    font-weight: 400;
    font-size: 12px;
    @include breakpoint-up(md) {
      font-size: 22px;
      font-weight: 350;
    }
  }

  .leaderboard-img {
    max-width: 1080px;
  }
  div > button {
    display: none !important;
  }
  .thumbnail-container {
    width: 98%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .leaderboard-thumbnail-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    gap: 8px;
    border-radius: 8px;
    width: 100%;
    max-width: 1010px;
    padding-bottom: 40px;
  }

  .count {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    position: absolute;
    left: 8px;
    top: 66%;
    border-radius: 39px;
    width: 35px;
    height: 35px;

    @include breakpoint-up(lg) {
      width: 45px;
      height: 45px;
      top: 130px;
    }
  }
  .count-1 {
    box-shadow: 0px 0px 8px #caa24b, inset 0px 0px 10px #836013;
    background: linear-gradient(251.02deg, #c9a045 19.17%, #9b7520 75.2%);
  }

  .count-2 {
    background: linear-gradient(258.34deg, #c6c6c6 -14.59%, #6c6c6c 77.49%);
    box-shadow: 0px 0px 8px #888585, inset 0px 0px 10px #3b3b3b;
  }
  .count-3 {
    background: linear-gradient(258.34deg, #1abdd3 -14.59%, #0c7684 77.49%);
    box-shadow: 0px 0px 8px #0492a5, inset 0px 0px 10px #053d44;
  }
  .thumbnail-image-container {
    position: relative;
    border-radius: 8px;
    padding: 1px;
    img {
      max-width: 100%;
      border-radius: 7px;
      @include breakpoint-up(md) {
        margin-bottom: -6px;
      }
    }
  }
  .count-1-border {
    background: linear-gradient(221.72deg, #c9a045 3.84%, rgba(155, 117, 32, 0) 97.21%);
  }

  .count-2-border {
    background: linear-gradient(208.77deg, #969696 6.86%, rgba(150, 150, 150, 0) 84.54%);
  }
  .count-3-border {
    background: linear-gradient(221.72deg, #0492a5 0%, rgba(4, 146, 165, 0) 78.84%);
  }
}
