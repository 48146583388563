@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.home-page-container {
  height: 97dvh;
  min-height: 97svh;
  max-height: 100lvh;

  @supports not (height: 100dvh) {
    height: 100vh;
  }
  background-position: center;
  background-repeat: no-repeat;

  background-size: cover;
  position: relative;

  @include breakpoint-up(md) {
    height: calc(100vh - 82px);
    min-height: calc(100vh - 82px);
    max-height: calc(100vh - 82px);
  }

  .tiles {
    background-color: var(--body-bg-color);
    padding: 24px 0;

    @include breakpoint-up(md) {
      position: absolute !important;
      bottom: 98px; // 20px + 48px + 20px
      width: 100%;
      background-color: transparent;
      padding: 0;
    }

    ul {
      bottom: 0px !important;
      position: relative !important;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;

      li {
        display: inline-flex;

        button {
          height: 6px;
          width: 6px;
        }
      }

      @include breakpoint-up(md) {
        &[data-type-landscape='false'] {
          background-color: transparent;
          flex-direction: row;
          z-index: 2;
          display: flex;
          padding-left: 0px;
          padding-right: 25px;
          gap: 8px;
          list-style-type: none;
          align-items: center !important;
          justify-content: center;
        }

        @include breakpoint-up(lg) {
          &[data-type-landscape='false'] {
            justify-content: flex-end;
          }
        }
      }
    }
  }
}

.tile {
  z-index: 3;
  cursor: pointer;
  max-width: 140px;
  transition: transform 0.2s ease-in-out;
  position: relative;
  border-radius: 8px;
  border: 1px solid var(--color-art-art-200);
  height: 177px;
}

.tile:not(.tile-active):hover {
  border-radius: 8px;
  border: 1px solid rgba(180, 251, 255, 0.5);
  background: linear-gradient(37deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 61.98%), lightgray 50% / cover no-repeat;
  box-shadow: 0px 0px 20px 0px rgba(180, 251, 255, 0.4);
}

.tile-active,
.tile-active:hover {
  border-radius: 8px;
  border: 1px solid var(--color-art-art-200, #b4fbff);
  background: linear-gradient(37deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 61.98%), lightgray 50% / cover no-repeat;
  box-shadow: 0px 0px 20px 0px rgba(180, 251, 255, 0.85);
}

.tile-image {
  border-radius: 8px;
  height: 100%;
}

.tile-text {
  position: absolute;
  text-align: left;
  bottom: 8px;
  padding: 5px;

  h4 {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }
}

.slider-nav-play-pause-container {
  width: 100%;
  display: flex;
  position: absolute;
  bottom: 20px;
  justify-content: space-between;
  padding: 0px 30px;
}

.slider-nav-container {
  position: relative;
  align-items: flex-end;
  justify-content: flex-end;
  display: flex;
  z-index: 312;
  gap: 16px;

  @include breakpoint-up(lg) {
    right: 0;
  }

  .slick-arrow-custom {
    background: none;
    border: solid 1px var(--gray-400);
    color: var(--gray-400);
    border-radius: 50%;
    cursor: pointer;
    height: 48px;
    padding: 12px;
    width: 48px;
  }

  .slick-arrow-custom:hover {
    border: solid 1px var(--white);
    color: var(--white);
  }

  .play-pause {
    position: absolute;
    cursor: pointer;
    svg {
      height: 48px;
      width: 48px;
    }
  }

  .disabled-nav {
    color: var(--gray-600);
  }

  svg {
    height: 24px;
    width: 24px;
    &:focus {
      color: var(--white);
    }
  }
}

.play-pause-container {
  height: 48px;
  width: 48px;
  z-index: 400;

  @include breakpoint-up(lg) {
    align-items: flex-start;
    justify-content: flex-start;
  }

  .play-pause {
    position: absolute;
    cursor: pointer;

    svg {
      height: 48px;
      width: 48px;
      vertical-align: super;
    }
  }
}

.slider-custom-nav {
  background-color: var(--gray-800);
  border-radius: 50%;
  cursor: pointer;
  height: 48px;
  padding: 12px;
  width: 48px;
}

//commented styles below for when we bring back progress bar:

// .slider-nav-container {
//   display: flex;
//   position: relative;
//   z-index: 312;
//   right: 266px;
//   margin-top: -268px;
//   align-items: center;
//   justify-content: center;
//   gap: 8px;

//   @include breakpoint-up(lg) {
//     position: absolute;
//     right: 28px;
//   }

//   .slick-arrow-custom {
//     background: none;
//     border: solid 1px var(--gray-400);
//     color: var(--gray-400);
//     border-radius: 50%;
//     cursor: pointer;
//     height: 48px;
//     padding: 12px;
//     width: 48px;
//   }

//   .slick-arrow-custom:hover {
//     border: solid 1px var(--white);
//     color: var(--white);
//   }

//   .play-pause {
//     position: absolute;
//     cursor: pointer;
//     svg {
//       height: 48px;
//       width: 48px;
//     }
//   }

//   .disabled-nav {
//     color: var(--gray-600);
//   }

//   svg {
//     height: 24px;
//     width: 24px;
//     &:focus {
//       color: var(--white);
//     }
//   }
// }

// .play-pause-container {
//   position: absolute;
//   left: calc(50% - 316px);
//   bottom: 285px;
//   height: 48px;
//   width: 48px;
//   display: flex;
//   align-items: center;
//   justify-content: flex-start;
//   gap: 16px;
//   z-index: 400;

//   @include breakpoint-up(lg) {
//     position: absolute;
//     align-items: flex-start;
//     justify-content: flex-start;
//     bottom: 98px;
//     left: 24px;
//   }

//   .play-pause {
//     position: absolute;
//     cursor: pointer;

//     svg {
//       height: 48px;
//       width: 48px;
//       vertical-align: super;
//     }
//   }
// }

// .slider-custom-nav {
//   background-color: var(--gray-800);
//   border-radius: 50%;
//   cursor: pointer;
//   height: 48px;
//   padding: 12px;
//   width: 48px;
// }

// .progress-bar {
//   width: 100%;
//   bottom: 40px;
//   position: relative;
//   z-index: 5000;
//   background: var(--color-neutral-gray-800, #303030);
//   height: 2px;

//   @include breakpoint-up(md) {
//     &[data-type-landscape='false'] {
//       width: 470px;
//       right: calc(50% - 284px);
//       position: absolute;
//       z-index: 5000;
//       bottom: 50px;
//     }
//   }

//   @include breakpoint-up(lg) {
//     &[data-type-landscape='false'] {
//       bottom: 242px;
//       width: 486px;
//       right: 20px;
//       position: absolute;
//     }
//   }
// }

// .progress {
//   height: 100%;
//   background: linear-gradient(90deg, #005a66 0%, #05c3dd 100%);
//   border-radius: 3px;
// }
