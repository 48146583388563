@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';
.form-module {
  position: relative;
  padding-bottom: var(--spacing-1);
  .form-module-container {
    display: flex;
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    padding: 8px 0;
  }
}
