.prev-button {
  padding: 0 !important;
  border-radius: 32px;
  height: 32px !important;
  width: 32px !important;

  &:disabled {
    svg {
      fill: none;

      path {
        fill: var(--gray-600);
      }

      rect[data-circle='true'] {
        stroke: var(--gray-600);
        stroke-width: 1px;
      }
    }
  }

  svg {
    fill: none;

    path {
      fill: var(--gray-100);
    }

    rect[data-circle='true'] {
      stroke: var(--gray-300);
      stroke-width: 1px;
    }
  }

  &:not(:disabled):hover {
    svg {
      fill: var(--white);
      path {
        fill: var(--black);
      }
    }
  }
}
