.share-button {
  position: relative;

  .tooltip-text {
    visibility: hidden;
    width: 109px;
    height: 37px;
    background: var(--white);
    position: absolute;
    z-index: 1;
    bottom: 130%;
    left: 50%;
    margin-left: -54px;
    color: var(--black);
    padding: 8px 16px;

    font-size: 14px;
    font-weight: 350;
    line-height: 150%;
    border-radius: 8px;
    letter-spacing: 0;
    text-align: center;
    opacity: 1;
  }

  .tooltip-text::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 100%;
    left: 50%;
    margin-left: -9px;
    border-width: 7px;
    border-style: solid;
    border-color: var(--white) transparent transparent transparent;
  }

  .tooltip-text.show {
    opacity: 1;
    visibility: visible;
    animation: fadeOut 400ms ease-in-out 2600ms;
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  svg {
    fill: var(--white);
  }

  button::before {
    content: none !important;
  }

  &[data-can-share='false'] {
    button:hover {
      svg {
        fill: black;
      }
    }
  }

  div[data-type='btn-text'] {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 18px;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.9px;
  }

  &[data-can-share='true'] {
    button {
      transition: none !important;
    }
    button:hover {
      background: none !important;
      color: var(--white) !important;
      filter: none;
      svg {
        fill: var(--white);
      }
    }

    button:active,
    button:focus-within {
      background: linear-gradient(250deg, var(--white) 19%, var(--gray-200) 75%) !important;
      color: var(--black) !important;
      svg {
        fill: var(--black) !important;
      }
    }
  }
  &.result-share {
    width: 134px;

    button {
      border-color: var(--gray-100) !important;
    }

    div[data-type='btn-text'] {
      font-size: 14px;
      letter-spacing: 0.7px;

      @media (max-width: 767px) {
        font-size: 12px;
      }
    }

    @media (max-width: 767px) {
      width: 124px;
    }
  }
}
