@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.lostandfound {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  flex-direction: column;
  gap: 8px;
  padding-top: 120px;
  text-align: center;
  h1 {
    width: 100%;
    line-height: 1;
    font-size: var(--font-size-specialty-display-mobile);
    font-weight: var(--font-weight-ultra-light);
    margin-bottom: 80px;
    @include breakpoint-up(md) {
      font-size: var(--font-size-specialty-display-desktop);
    }
  }
  .content {
    background-color: white;
  }
}
