@import '../../../../styles/variables.breakpoints.module.scss';
@import '../../../../styles/mixins.scss';

.calendar__view-controls {
  display: flex;
  align-items: center;
  margin-left: auto;
  height: 43px;

  label {
    margin-bottom: 0;
    margin-left: auto;
    text-transform: uppercase;
  }

  button.calendar__view-control {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    cursor: pointer;
    transition: opacity 0.2s;
    border-radius: 6px;
    border: 1px solid var(--white);
    background: rgba(0, 0, 0, 0.1);
    &.button-group-right {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &.button-group-left {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    &.calendar__view-control--is-active:hover {
      opacity: 0.9;
    }
    &:not(.calendar__view-control--is-active):hover {
      background: var(--turquoise-800);
    }
    span {
      text-indent: -9999px;
    }

    i {
      width: 40px;
      height: 40px;
    }

    &.calendar__view-control--is-active {
      background: var(--white);
      cursor: default;
    }

    span {
      display: none;
    }
  }
  .calendar__button-group {
    display: flex;

    .button {
      flex: 1;
      margin-top: 0;

      + .button {
        margin-top: 0 !important;
      }
    }
  }
}
