@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.container {
  align-items: stretch;
  position: relative;
  margin: auto;
  padding: 32px 16px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 40px;

  @include breakpoint-up(md) {
    padding: 32px 24px;
    gap: 48px;
  }

  @include breakpoint-up(lg) {
    max-width: 1648px; //Accounts for 24px in padding-left and padding-right
    padding: 72px 24px;
  }

  h2 {
    font-weight: 600;
    margin: 0;
    line-height: 100%;
    text-shadow: 0px 0px 30px rgba(0, 0, 0, 0.7);
    text-transform: lowercase;

    @include breakpoint-up(md) {
      font-size: 40px;
      margin: var(--spacing-0);
    }

    @include breakpoint-up(lg) {
      font-size: var(--font-size-h1-desktop);
      line-height: 120%;
    }
  }
}

.group-ticketing {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: wrap;
  min-width: 280px;
  min-height: 229px;

  .gradient-block {
    border-radius: 0 0 16px 16px;
    width: 100%;
    padding: 16px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    @include breakpoint-up(md) {
      border-radius: 0 16px 16px 0;
      padding: 16px;
    }

    .col-wrapper--content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 16px;
      top: 100px;
      margin: unset;
      align-self: stretch;

      &.center-aligned {
        margin: auto;
      }

      @include breakpoint-up(md) {
        gap: 24px;
        margin: auto;
      }

      h4 {
        margin: var(--spacing-0);
        font-weight: var(--font-weight-extra-bold);
        line-height: 100%;

        @include breakpoint-up(md) {
          font-size: var(--font-size-h4-mobile);
        }

        @include breakpoint-up(lg) {
          font-size: var(--font-size-h1-mobile);
          line-height: 120%;
        }
      }

      h6 {
        margin: var(--spacing-0);
        font-weight: var(--font-weight-extra-bold);
        font-size: var(--font-size-specialty-eyebrow-mobile);
        line-height: 100%;

        @include breakpoint-up(lg) {
          font-size: var(--font-size-specialty-eyebrow-desktop);
        }
      }

      p {
        @include body-copy(md);
        margin: var(--spacing-0);
        font-weight: var(--font-weight-light);
        line-height: 120%;
        letter-spacing: 0.8px;

        &:last-child {
          margin-bottom: var(--spacing-0);
        }
      }

      .text-content {
        display: flex;
        flex-direction: column;
        gap: 8px;
        text-shadow: 0px 0px 16px var(--black);
        align-self: stretch;
      }

      .btn-container {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;
        gap: 16px;
        width: 100%;

        button,
        a {
          margin-bottom: var(--spacing-0);
          font-size: var(--font-size-body-sm);
          text-wrap: wrap;
          letter-spacing: 0.7px;

          @include breakpoint-up(md) {
            font-size: 18px;
            letter-spacing: 0.9px;
          }
        }

        &.center-aligned {
          justify-content: center;
        }
      }
    }
  }

  .col-wrapper--media {
    padding-right: var(--spacing-0);

    .media-column {
      .media-image {
        border-radius: 16px 16px 0 0;
        min-height: 180px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        @include breakpoint-up(sm) {
          min-height: 200px;
        }

        @include breakpoint-up(md) {
          border-radius: 16px 0 0 16px;
          min-height: 229px;
        }

        @include breakpoint-up(lg) {
          min-height: 370px;
        }
      }

      .media-overlay {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 78.61%, #000 100%);
        height: 100%;
        width: 100%;
        position: absolute;
        top: var(--spacing-0);
        left: var(--spacing-0);
        z-index: var(--z-index-1);

        @include breakpoint-up(md) {
          background: linear-gradient(90deg, rgba(0, 0, 0, 0) 72.81%, rgba(0, 0, 0, 0.9) 100%);
        }
      }
    }

    .video-container {
      height: 180px;
      border-radius: 16px 16px 0 0;
      overflow: hidden;

      @include breakpoint-up(sm) {
        height: 200px;
      }

      @include breakpoint-up(md) {
        border-radius: 16px 0 0 16px;
        height: 229px;
      }

      @include breakpoint-up(lg) {
        height: 370px;
      }

      video {
        object-fit: cover;
        min-height: 180px;

        @include breakpoint-up(md) {
          min-height: 229px;
        }

        @include breakpoint-up(lg) {
          min-height: 370px;
        }
      }
    }
  }

  &.has-media-in-right-col {
    .gradient-block {
      @include breakpoint-up(md) {
        border-radius: 16px 0 0 16px;
      }
    }

    .media-column {
      .media-image {
        @include breakpoint-up(md) {
          border-radius: 0 16px 16px 0;
        }
      }

      .media-overlay {
        @include breakpoint-up(md) {
          background: linear-gradient(270deg, rgba(0, 0, 0, 0) 72.81%, rgba(0, 0, 0, 0.9) 100%);
        }
      }

      .video-container {
        @include breakpoint-up(md) {
          border-radius: 0 16px 16px 0;
        }
      }
    }
  }
}
