@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';
.vertical-divider-styles {
  border: none;
  height: 40px;
  margin: 24px 16px;
  background-color: var(--gray-700);
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-global {
  position: fixed;
  left: 0;
  top: -200px;
  z-index: 9000;
  display: flex;
  align-items: center;
  background-color: var(--gray-950);
  width: 100%;
  overflow: visible;
  transition: background-color 0.2s ease;
  justify-content: space-between;
  transition: top 0.5s ease-in-out;

  .header-global__wrapper {
    display: flex;
    justify-content: space-between;
  }

  li {
    list-style: none;

    a,
    span {
      font-weight: var(--font-weight-light);
    }
  }

  .brand-logo {
    line-height: 0;
    //need to override next/link component styles
    a {
      padding: 0 !important;
    }
  }

  .brand-headline-container {
    @extend .center;

    h2 {
      font-size: 24px;
      font-weight: 200;
      line-height: 100%;
      letter-spacing: 0.48px;
      text-transform: lowercase;
    }
  }

  .vertical-divider {
    @extend .vertical-divider-styles;
    width: 1.5px;
  }

  .vertical-divider-right {
    @extend .vertical-divider-styles;
    width: 1px;
  }

  .subheadline-cta-container {
    @extend .center;

    .subheadline {
      display: flex;
      align-items: center;
      justify-content: center;
      //custom breakpoint from Simon
      @media (max-width: 1023px) {
        display: none;
      }
      p {
        color: var(--gray-300);
        font-size: 16px;
        font-weight: 350;
        margin: 0;
      }
      span {
        margin-left: 4px;
        color: var(--gold);
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  &.header-global--desktop {
    height: var(--header-height-desktop);
    padding-top: 0;

    .header-global__column {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  &.show {
    top: 0;
  }
}

.standalone-btn {
  position: fixed;
  width: 100%;
  z-index: 1000;
  padding: 0 16px;
  margin-bottom: 16px;
  button {
    width: 100%;
  }
}
