@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.slide-module {
  position: fixed;
  top: 0;
  width: 320px;
  height: 100%;
  transition: right 0.3s ease;
  z-index: 10000;

  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;

  @media (max-height: 450px) {
    overflow-y: scroll;
  }

  background: rgba(0, 0, 0, 0.8);
  box-shadow: 4px 4px 16px 0px rgba(0, 0, 0, 0.2), 0px 4px 24px 0px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(12px);

  @include breakpoint-up(md) {
    width: 512px;
  }

  .bottom-module {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  hr {
    margin: 0 !important;
  }

  .module-content-image {
    display: flex;
    width: 320px;
    height: 303px;
    padding: 24px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    flex-shrink: 0;

    background: linear-gradient(180deg, rgba(2, 22, 45, 0) 49.83%, #02162d 76.9%);

    background-size: cover;
    background-position: center;
    border-bottom: 1px solid var(--Neutral-Gray-500, #757575);

    @include breakpoint-up(md) {
      width: 512px;
    }

    svg {
      @include svg-btn(white);
      position: absolute;
      top: 10px;
      right: 10px;
    }

    .module-content-image-content {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-self: stretch;
      cursor: pointer;

      .share-icon {
        img {
          border-radius: 40px;
          border: 1px solid var(--Neutral-Gray-500, #757575);
          background: var(--Transparent-Black-10, rgba(0, 0, 0, 0.1));
          padding: 7px;
          backdrop-filter: blur(4px);
        }
      }

      h4 {
        font-weight: 600;
      }

      p {
        height: 42px;
        align-self: stretch;
        color: var(--Neutral-Gray-300, #ababab);
        font-size: 14px;
      }
    }
  }

  .module-content-calender {
    display: flex;
    flex-direction: column;
    width: 320px;
    height: 250px;
    padding: var(--space-space-32, 32px) 24px;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
    flex-wrap: wrap;

    @include breakpoint-up(md) {
      width: 512px;
    }

    .list-item-ctas {
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
    }

    .list-item-date-holder {
      display: flex;
      justify-content: left;
      padding-bottom: 32px;

      .list-item__date {
        display: flex;
        align-items: center;
        line-height: var(--line-height-body);
        white-space: nowrap;
        text-transform: uppercase;

        span {
          color: var(--white);

          &.list-item__formatted-date {
            font-weight: var(--font-weight-extra-bold);
            color: var(--gray-100);
          }

          &.list-item__separator,
          &.list-item__formatted-day {
            font-weight: var(--font-weight-light);
          }

          &.list-item__separator {
            padding: 0 8px;
          }
        }
      }

      p.price {
        color: var(--gray-300);
        min-width: 250px;
        margin-bottom: 8px;

        span {
          font-weight: var(--font-weight-extra-bold);
        }
      }
    }
  }

  .module-content-footer {
    display: flex;
    width: 320px;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    @include breakpoint-up(md) {
      width: 512px;
    }

    .module-content-footer-buttonss {
      &:first-of-type {
        display: flex;
        height: 38px;
        justify-content: flex-end;
        align-items: flex-start;
        gap: var(--space-space-16, 16px);
        align-self: stretch;
      }
    }

    .module-content-footer-buttons {
      display: block;
      width: 100%;

      @include breakpoint-up(md) {
        display: flex;
        gap: 14px;
        justify-content: center;
      }

      button,
      a {
        margin: auto 0px auto;

        @include breakpoint-up(md) {
          width: 206px;
        }

        &:first-child {
          margin-bottom: 16px;

          @include breakpoint-up(md) {
            margin: auto 0px auto;
          }
        }
      }
    }

    .module-content-footer-disclaimer {
      p {
        color: var(--Neutral-Gray-500, #757575);
        margin: auto;
        text-align: center;
        width: 100%;
        display: inline-block;
        font-family: Graphie;
        font-size: 12px;
        font-style: normal;
        font-weight: 350;
        line-height: 150%;
      }
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(4px);
  z-index: 999;
  transition: opacity 0.3s ease;
  opacity: 1;
  pointer-events: none;
  z-index: 9999;
  cursor: pointer;
}
