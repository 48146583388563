@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.header-global {
  position: fixed;
  left: 0;
  z-index: 9000;
  display: flex;
  justify-content: flex-start;
  align-self: stretch;
  flex-direction: column;
  flex-shrink: 0;
  width: 100%;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.07) 100%);
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(12px);
    z-index: -1;
  }

  .height-100 {
    height: 100%;
  }

  .header-global__wrapper {
    display: flex;
    flex: 1 0 0;
    padding-left: 24px;
    gap: 24px;
    align-self: stretch;
    align-items: center;
  }

  li {
    a,
    span {
      font-weight: 600;
      color: #fff;
      letter-spacing: 0.8px;
    }

    @include breakpoint-down(md) {
      margin-top: 16px;
    }
  }

  .brand-logo {
    display: flex;
    align-items: center;
    width: var(--header-brand-logo-width-mobile);
    min-width: var(--header-brand-logo-width-mobile);
    height: var(--header-brand-logo-height-mobile);
    min-height: var(--header-brand-logo-height-mobile);

    @include breakpoint-up(md) {
      width: var(--header-brand-logo-width-desktop);
      min-width: var(--header-brand-logo-width-desktop);
      height: var(--header-brand-logo-height-desktop);
      min-height: var(--header-brand-logo-height-desktop);
      margin-right: 72px;
    }

    @media (min-width: 1280px) {
      margin-left: 8px;
    }

    a {
      display: flex;
    }
  }

  &.header-global--desktop {
    height: var(--header-height-desktop);
    padding-top: 0;

    a {
      font-weight: 600;
      color: var(--white);
      letter-spacing: 0.8px;
    }

    .header-global__column {
      display: flex;
      gap: 16px;
      align-items: center;
      height: 100%;
    }

    button {
      margin-left: auto;
      margin-right: -16px;
    }

    .nav-link {
      display: flex;
      border: 0;
      height: 100%;
      align-items: center;
      border-left: 1px solid transparent;
      border-image: linear-gradient(to bottom, rgba(117, 117, 117, 0), rgba(117, 117, 117, 1), rgba(117, 117, 117, 0)) 1 / 1px / 0 stretch;
      border-image-slice: 1;
      border-image-width: 0 0 0 1px;
      padding: 24px;
      gap: 7px;

      img {
        display: flex;
        align-items: center;
        gap: 2px;
      }

      &:hover {
        cursor: pointer;
        border: 0;
        border-left: 1px solid transparent;
        border-image: linear-gradient(to bottom, rgba(5, 195, 221, 0), var(--brand-primary), rgba(5, 195, 221, 0)) 1 / 1px / 0 stretch;
        border-image-slice: 1;
        border-image-width: 0 0 0 1px;

        span {
          color: var(--brand-primary) !important;
        }
      }
    }
    .nav-container {
      display: flex;
      height: 100%;
      flex-grow: 1;
    }
    .end-buttons {
      display: flex;
      align-items: center;
      gap: 24px;
    }

    nav {
      color: var(--white);

      > ul {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding-left: 0;
        padding-bottom: 0;
        margin: 0;
        text-align: center;

        li {
          display: flex;
          align-items: center;
          height: 100%;
          padding: 8px 24px;
          font-weight: var(--font-weight-light);
          border: 0;
          position: relative;
          border-left: 1px solid transparent;
          border-image: linear-gradient(to bottom, rgba(117, 117, 117, 0), rgba(117, 117, 117, 1), rgba(117, 117, 117, 0)) 1 / 1px / 0 stretch;
          border-image-slice: 1;
          border-image-width: 0 0 0 1px;

          &:hover {
            cursor: pointer;
            border-left: 1px solid var(--brand-primary);
            border-image: none;
            transition: border-left 0.1s ease-out;
            background: rgba(0, 0, 0, 0.1);

            span {
              color: var(--brand-primary) !important;
            }

            .submenu {
              display: flex;
              position: absolute;
              top: 100%;
              left: -1px;
              align-items: stretch;
              min-width: 558px;
              width: 100%;
              animation: slideDown 0.5s ease-out forwards;
              max-height: 460px;
              overflow-y: auto;
              -webkit-backdrop-filter: blur(12px);
              backdrop-filter: blur(12px);
              scrollbar-color: var(--gray-800) transparent;
              background: linear-gradient(180deg, rgba(0, 0, 0, 0.07) 0%, rgba(0, 0, 0, 0.42) 100%);
              scrollbar-width: thin;
              padding-left: 1px;
              border-left: 1px solid transparent;
              border-image-slice: 1;
              border-image: linear-gradient(to bottom, var(--brand-primary) 0%, rgba(0, 0, 0, 0) 100%) 1 / 2px / 0 stretch;
              border-image-width: 0 0 0 1px;
              border-radius: 0px 0px 8px 8px;

              @media (min-width: 1920px) {
                min-width: 692px;
              }

              &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: -1;
                border-bottom-right-radius: 8px;
              }

              @keyframes slideDown {
                from {
                  transform: translateY(-20px);
                }
                to {
                  transform: translateY(0);
                }
              }

              .submenu-left {
                display: flex;
                flex-direction: column;
                min-width: 256px;
                box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.2);
                margin-left: -1px;
                border-radius: 0px 0px 0px 8px;
                background: rgba(0, 0, 0, 0.8);
                min-height: 263px;
                height: 100%;

                @media (min-width: 1920px) {
                  min-width: 390px;
                }
              }

              .submenu-links {
                display: flex;
                flex-direction: column;
                padding-left: 0;
                margin-top: 16px;
                margin-bottom: 16px;
                justify-content: flex-start;

                li {
                  border: none;
                  align-items: flex-start;
                  text-align: left;
                  display: flex;
                  color: var(--white);
                  padding: 8px 32px;
                  &:hover {
                    background: var(--gray-800);
                    font-weight: bold;
                  }
                  .group-title {
                    cursor: default !important;
                  }
                }
                a {
                  text-transform: none;
                  letter-spacing: 0.8px;
                  font-weight: 300;
                  width: 100%;
                  &:hover {
                    background: var(--gray-800);
                    font-weight: bold;
                  }
                }
                hr {
                  height: 1px;
                  flex: 0 0 auto;
                  width: 100%;
                  border: none;
                  margin-top: 8px;
                  padding: 0;
                  background: linear-gradient(90deg, rgba(117, 117, 117, 0) 0%, #757575 49.97%, rgba(117, 117, 117, 0) 100%);
                }
                .group-title {
                  color: #c9a045;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 100%;
                  letter-spacing: 2.4px;
                  text-transform: uppercase;
                  cursor: default !important;
                  pointer-events: none;
                }
                .section {
                  display: flex;
                  flex-direction: column;
                }
              }

              .submenu-feature {
                cursor: pointer;
                display: flex;
                flex-direction: column;
                padding: 24px;
                width: 100%;
                gap: 10px;
                justify-content: center;
                border-radius: 0px 0px 8px 0px;

                img {
                  border-radius: 8px;
                  border: 1px solid var(--gray-500);
                  background: lightgray 0px -113.847px / 100% 202.229% no-repeat;
                  box-shadow: 0px 0px 20px 0px rgba(180, 251, 255, 0.4);
                }

                .submenu-text {
                  text-align: start;

                  h4 {
                    font-style: normal;
                    font-weight: 600;
                    line-height: 120%;
                    margin: 0;
                  }

                  p {
                    color: var(--gray-100) !important;
                    font-size: 14px;
                    margin: 0;
                  }

                  &:hover {
                    color: var(--brand-primary);

                    p {
                      color: var(--brand-primary) !important;
                    }
                  }
                }
              }
            }
          }

          div.submenu {
            li {
              display: flex;
              align-items: flex-start;
              align-self: stretch;
              padding: 0;
              display: flex;
              gap: 4px;
              padding: 8px 32px 8px 16px;
              a {
                color: var(--white);
              }
            }
          }
          div.submenu {
            display: none;
          }

          .menu-item-wrapper {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 0 var(--spacing-1);
            cursor: pointer;
            display: block;
            padding: 0;
            white-space: nowrap;

            .caret {
              position: absolute;
              left: 50%;
              top: 33px;
              transform: translate(-50%, -50%);

              svg {
                opacity: 50%;
                height: 10px;
                width: 10px;
              }
            }

            a,
            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  &.header-global--mobile {
    a {
      font-weight: 600;
      color: var(--white);
      letter-spacing: 0.8px;
    }
    .navbar-mobile {
      .header-mobile {
        position: fixed;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.07) 100%);
        box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(12px);
        z-index: 1000;
        height: var(--header-height-mobile);

        .nav-drawer-toggler {
          display: flex;
          align-items: center !important;
          justify-content: center;
          margin-left: 16px;
          margin-right: 16px;
        }

        .brand-logo {
          margin-right: auto;
        }

        button {
          margin-left: auto;
        }
      }

      .sidebar {
        top: var(--header-height-mobile);
        position: fixed;
        left: 0;
        height: 100%;
        width: 320px;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.31) 0%, rgba(0, 0, 0, 0.05) 100%);
        box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(12px);
        transform: translateX(-100%);
        transition: transform 0.3s ease-in-out;
        z-index: 999;

        .sidebar__scroller {
          height: calc(100% - var(--header-height-mobile));
          padding-bottom: 120px;
          overflow: auto;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.31) 0%, rgba(0, 0, 0, 0.05) 100%);
          box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.2);
          backdrop-filter: blur(12px);
        }

        &.open {
          transform: translateX(0);
        }

        nav {
          ul {
            width: 100%;
            padding: 0;

            li {
              padding: 16px 24px;
              list-style: none;
              font-size: var(--font-size-heading-sm-mobile);

              a {
                color: #fff;
                text-decoration: none;
                font-weight: 300;
                letter-spacing: 0.8px;
              }

              &.is-open {
                .caret {
                  transform: rotate(90deg);
                }
              }

              .menu-item-wrapper {
                position: relative;
                display: flex;
                justify-content: space-between;
                width: 100%;
                padding: 0 var(--spacing-1);
                cursor: pointer;

                .caret svg {
                  width: 13.5px;
                  transform: rotate(270deg);
                }
              }

              .submenu {
                li {
                  list-style: none;
                  margin-top: 0;
                  padding: 16px 24px 16px 40px;
                  border: none;
                  text-transform: none;
                  font-weight: 300;
                }
                .group-title {
                  color: #c9a045;
                  font-family: Graphie;
                  font-size: 11px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 100%;
                  letter-spacing: 2.2px;
                  text-transform: uppercase;
                }

                hr {
                  height: 1px;
                  width: 100%;
                  border: none;
                  margin-top: 8px;
                  margin-bottom: 8px;
                  background: linear-gradient(90deg, rgba(117, 117, 117, 0) 0%, #757575 49.97%, rgba(117, 117, 117, 0) 100%);
                }

                .section {
                  display: flex;
                  flex-direction: column;
                }
              }
            }
          }

          .nav-link {
            display: flex;
            align-items: center;
            padding: 0 44px;
            gap: 8px;
          }
        }
      }

      .overlay {
        display: none;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.31) 0%, rgba(0, 0, 0, 0.05) 100%);
        box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(12px);
        z-index: 998;

        &.open {
          display: block;
        }
      }
    }
  }
}
