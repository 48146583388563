@import '../../../styles/variables.breakpoints.module.scss';

.carousel-fullscreen {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 100%;
  height: 100vh;
  text-align: center;

  .carousel-fullscreen__wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100%;
    padding-top: 110px;

    @include breakpoint-up(lg) {
      padding-top: 165px;
    }

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: background-image 0.4s ease-in-out;
  }

  .headlines {
    display: flex;
    align-items: center;
    min-height: 100px; // prevent jump if one slide has single line of headline text
    padding-right: 15px;
    padding-left: 15px;

    .headlines__wrapper {
      width: 100%;
    }

    h2 {
      margin: 0;
    }

    @include breakpoint-up(sm) {
      min-height: 145px;
    }

    @include breakpoint-up(lg) {
      min-height: 165px;
      padding-right: 0;
      padding-left: 0;
    }
  }

  .content {
    display: flex;
    flex: 1;

    .content__wrapper {
      width: 100%;
      min-width: 100%;
      margin-top: auto;
      background: linear-gradient(180deg, rgba(4, 4, 5, 0) 6.83%, #040506 74.06%);
      padding-bottom: 60px;
      padding-top: var(--spacing-3);

      p {
        margin-bottom: 0;
      }
    }
  }
}
