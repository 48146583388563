@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.show-calendar {
  min-height: 500px;
  max-width: 1600px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;

  width: 100%;
  position: relative;
  z-index: inherit;
  padding: 0 16px;

  @include breakpoint-up(md) {
    padding: 0 24px;
  }

  @include breakpoint-up(xl) {
    padding: 0;
  }

  .edp-image {
    display: block;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }

    object-fit: contain;
  }

  .calendar__months,
  .calendar__grid {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .calendar__month-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-right: -12px;
    margin-left: -12px;

    .calendar__day {
      position: relative;
      width: 100%;
      padding-right: 12px;
      padding-left: 12px;
      flex: 0 0 33.33333333%;
      max-width: 33.33333333%;
    }
  }

  .calendar__grid-wrapper {
    gap: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .calendar__grid-day {
      flex: 1;
      min-height: 40px;
      border-radius: 8px;
      background: rgba(0, 0, 0, 0.2);
      box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(12px);
      flex: 0 0 31.333%;
      max-width: 31.333%;
      min-width: 230px;
      position: relative;
      margin: 8px 0;
      padding-bottom: 8px;

      @media only screen and (max-width: 765px) {
        max-width: unset;
        min-width: unset;
        width: 100%;
      }

      @media only screen and (max-width: 520px) {
        flex: 0 0 48.333%;
        max-width: unset;
        min-width: unset;
        width: 100%;
      }

      @media only screen and (max-width: 450px) {
        flex: auto;
        max-width: unset;
        min-width: unset;
        width: 100%;
      }

      &.calendar__day--has-no-events {
        min-height: 92px;
      }

      &.calendar__day--has-no-events-active {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;

        span {
          width: 100%;
          color: var(--gray-500);
        }
      }

      .calendar__no-events {
        display: flex;
        justify-content: center;
        width: 100%;
        align-items: center;
        position: absolute;
        top: 35%;
        @include breakpoint-up(md) {
          top: 50%;
        }
      }

      .event-container {
        position: relative;
        padding: 4px 8px;
        img {
          width: 100%;
          height: 100px;
          object-fit: cover;
          object-position: center;
          border-radius: 8px;
        }

        .event-title {
          position: absolute;
          bottom: 1.4rem;
          font-size: var(--font-size-body-md);
          color: var(--white);
          padding: 0 8px;
          text-wrap: wrap;
        }

        div {
          display: flex;
          justify-content: center;
          align-content: center;
          overflow: hidden;
          position: absolute;
          bottom: 0.5rem;
          padding-left: 8px;
          backdrop-filter: none;
          box-shadow: none;

          span {
            color: var(--turquoise-300);
            font-size: 11px;
          }
        }
      }

      h6 {
        color: var(--white);
        text-align: center;
        font-size: var(--font-size-h6-desktop);
        font-style: normal;
        font-weight: var(--font-weight-extra-bold);
        line-height: 120%;
        text-transform: lowercase;
        display: flex;
        padding: 16px 0px;
        justify-content: center;
        align-items: flex-end;
        bottom: 0px;
        background: linear-gradient(219deg, var(--turquoise-500) -145.07%, var(--black) 64.59%);
        margin-bottom: 0px;
        border-bottom: 1px solid var(--gray-700);
      }

      @include breakpoint-up(md) {
        min-height: 72px;
      }

      &.calendar__day--has-events {
        + .calendar__day--is-from-different-month,
        + .calendar__day--has-no-events {
          border-left-width: 0;
          margin-left: 0;
        }
      }

      &.calendar__no-events {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        span {
          width: 100%;
        }
      }

      .calendar__day-header {
        padding-bottom: 8px;

        .calendar__day--has-passed {
          border-radius: 8px 0px 8px 0px;
          display: flex;
          width: 64px;
          height: 56px;
          padding: 8px;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          gap: 4px;
          border-radius: 8px 0px 8px 0px;
          background: linear-gradient(248deg, var(--gray-900) 17.66%, var(--black) 71.37%);
          color: var(--black);

          .grid-day {
            color: var(--gray-100);
            text-align: center;
            font-size: var(--font-size-body-xs);
            font-style: normal;
            font-weight: var(--font-weight-extra-bold);
            line-height: 100%;
            letter-spacing: 2.4px;
            text-transform: uppercase;
          }

          .grid-date {
            overflow: hidden;
            color: var(--gray-100);
            text-align: center;
            text-overflow: ellipsis;
            font-size: 24px;
            font-style: normal;
            font-weight: var(--font-weight-extra-bold);
            line-height: 100%;
            letter-spacing: 2.4px;
            text-transform: uppercase;
          }
        }

        .calendar__day--is-from-different-month > * {
          display: none;
        }

        .calendar__day--shared {
          border-radius: 8px 0px 8px 0px;
          display: flex;
          width: 49px;
          height: 43px;
          padding: 5px 10px;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          gap: 4px;

          .grid-day,
          .grid-date {
            text-align: center;
            font-style: normal;
            font-weight: var(--font-weight-extra-bold);
            line-height: 100%;
            letter-spacing: 2.4px;
            text-transform: uppercase;
          }

          .grid-day {
            font-size: 11px;
          }

          .grid-date {
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 20px;
          }
        }
        .calendar__day--has-no-events {
          @extend .calendar__day--shared;
          background: rgba(255, 255, 255, 0.07);
        }

        .calendar__day--upcoming-event {
          @extend .calendar__day--shared;
          color: var(--white);
          background: rgba(255, 255, 255, 0.07);

          .grid-day {
            color: var(--white);
          }

          .grid-date {
            color: var(--white);
          }
        }

        .calendar__day--active {
          @extend .calendar__day--shared;
          background: linear-gradient(248deg, var(--gray-200) 17.66%, var(--white) 71.37%);
          color: var(--black);

          .grid-day {
            color: var(--black);
          }

          .grid-date {
            color: var(--gray-900);
          }
        }
      }

      .calendar__day-wrapper {
        height: 100%;
      }

      .calendar__no-events_with_margin {
        span {
          color: var(--gray-500);
          font-weight: var(--font-weight-extra-bold);
        }
      }

      .calendar__events {
        box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(12px);

        &.calendar-height-100 {
          height: 100%;
        }

        &.calendar__no-events {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
        }
      }

      button {
        cursor: pointer;
        width: 100%;

        color: var(--gray-200);
        text-align: center;
        font-size: var(--font-size-h6-desktop);
        font-style: normal;
        font-weight: var(--font-weight-extra-bold);
        border: 0;
        background: none;
        box-shadow: none;
        border-bottom: 1px solid var(--gray-800);
        border-radius: 0px;
        padding: 12px 0;

        &:hover:not([disabled]) {
          background: var(--black);
          opacity: 1;
          box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.2);
          backdrop-filter: blur(12px);
          color: var(--turquoise-500);
        }

        &.calendar__event--is-disabled,
        &.calendar__event--is-disabled:hover {
          cursor: default;
          opacity: 1;
          color: var(--gray-700);
        }

        @include breakpoint-up(md) {
          font-size: var(--font-size-h5-desktop);
        }

        &.calendar__day--last-index {
          border-bottom: 0px solid var(--gray-800);
        }
      }
    }
  }

  &.calendar--grid-view {
    .calendar__day {
      min-height: 40px;
      margin-top: -1px;
      margin-left: -1px;
      border-style: solid;
      border-width: 1px;
      border-color: transparent;
      border-radius: 8px;
      border: 1px solid var(--gray-800);
      background: rgba(0, 0, 0, 0.1);
      box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.2);
      -webkit-backdrop-filter: blur(12px);
      backdrop-filter: blur(12px);
    }
  }

  .calendar__filters {
    width: 100%;
  }

  .calendar__month-navs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 16px;
    cursor: pointer;

    .calendar__today-button {
      display: flex;
      height: 43px;
      padding: 8px 16px;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
      border: 1px solid var(--Neutral-White, #fff);
      font-family: Graphie;
      color: var(--Neutral-White);
      font-size: 18px;
      font-style: normal;
      font-weight: 350;
      line-height: 150%;
      background: rgba(0, 0, 0, 0.1);

      &:hover {
        cursor: pointer;
        background: var(--Neutral-White, #fff);
        color: var(--gray-950);
      }
    }
    .calendar__divider {
      width: 1px;
      height: 40px;
      background: var(--gray-500);
    }

    .calendar-nav-buttons {
      display: flex;

      .previous button,
      .next button {
        width: 43px;
        height: 43px;
        border: 1px solid var(--Neutral-White, #fff);
        background: rgba(0, 0, 0, 0.1);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;

        &:hover {
          cursor: pointer;
          background: var(--Neutral-White, #fff);
          svg path {
            fill: var(--gray-600);
          }
        }
        &:disabled {
          background: rgba(0, 0, 0, 0.1);
          svg path {
            fill: var(--gray-700);
          }
          cursor: not-allowed;
        }
      }

      .previous button {
        border-radius: 8px 0px 0px 8px;
        border-right: 0.5px solid var(--Neutral-White, #fff);
      }

      .next button {
        border-radius: 0px 8px 8px 0px;
        border-left: 0;
      }
    }

    .calendar__month-nav {
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        margin: 0px;
      }

      a {
        margin-top: 8px;
      }
    }

    &.calendar__month_navs_hide {
      width: auto;
    }

    h2 {
      font-size: var(--font-size-h4-mobile);
      font-style: normal;
      font-weight: var(--font-weight-extra-bold);
      line-height: 120%;

      @include breakpoint-up(lg) {
        font-size: var(--font-size-h1-mobile);
        font-style: normal;
        font-weight: var(--font-weight-light);
      }
    }

    &.calendar__month-navs--top {
      margin-right: auto;
      gap: 16px;
      padding-bottom: 40px;

      .calendar__month-nav {
        margin-bottom: -3px;

        span {
          display: none;
        }
      }
    }

    &.calendar__month-navs--bottom {
      justify-content: space-between;
      display: flex;
      align-items: center;
      gap: 24px;

      .calendar__current-month-year-title {
        display: none;
      }

      button {
        padding: 0;
      }

      .calendar__month-nav {
        padding: 8px;
        gap: 8px;

        @include breakpoint-up(lg) {
          gap: 16px;
        }

        button {
          padding: 0;
        }

        &:hover {
          &:not(:has(button:disabled)) {
            border-radius: 50px;
            opacity: 0.8;
          }
        }
      }
    }

    .calendar__month-nav {
      display: flex;
      align-items: center;

      h2 {
        margin: 0;
      }

      button {
        background: transparent;
        display: flex;
        width: 48px;
        height: 48px;
        padding: 8px 13px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border: 0;
        cursor: pointer;

        &:disabled {
          cursor: default;

          svg path {
            fill: var(--gray-600);
          }
        }

        &:hover {
          &:not(button:disabled) {
            border-radius: 1000px;
            background: var(--gray-800);
            opacity: 0.8;
          }
        }
      }
    }

    .calendar__month-nav--next-disabled {
      h2 {
        cursor: default;
        color: var(--gray-600);
      }
    }

    .calendar__month-nav--prev-disabled {
      h2 {
        cursor: default;
        color: var(--gray-600);
      }
    }

    .calendar__current-month-year-title h2 {
      margin: 0;
      cursor: default;
    }
  }

  .calendar__month {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .calendar__day-name-labels {
      display: flex;
      text-align: center;
      gap: 8px;
      padding-left: 8px;

      .calendar__day-name-label {
        flex: 1;
        color: var(--gray-200);
        text-align: left;
        font-size: var(--font-size-body-sm);
        font-style: normal;
        font-weight: var(--font-weight-extra-bold);
        line-height: 100%;
        letter-spacing: 2.8px;
        text-transform: uppercase;
      }
    }

    .calendar__day-name-labels {
      text-transform: uppercase;
      font-size: var(--font-size-body-xs);
      margin-bottom: 16px;
    }

    .calendar__week {
      display: flex;
      align-items: flex-start;
      gap: 8px;
      align-items: stretch;

      .calendar__day {
        flex: 1;
        min-height: 40px;
        margin-top: -1px;
        margin-left: -1px;
        border-style: solid;
        border-width: 1px;
        border-color: transparent;
        border-radius: 8px;
        background: rgba(0, 0, 0, 0.2);
        box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(12px);
        position: relative;

        &.calendar__day--is-from-different-month {
          background: none;
          border: none;
          backdrop-filter: none;
          box-shadow: none;
        }

        .calendar__no-events {
          display: flex;
          justify-content: center;
          width: 100%;
          align-items: center;
          height: 100%;
          position: absolute;
          top: 35px;
        }

        .calendar__no-events_with_margin {
          padding: 0px 0 50px 0;

          span {
            color: var(--gray-500);
            font-weight: var(--font-weight-extra-bold);
          }
        }

        .event-container {
          position: relative;
          padding: 4px 8px;
          display: block;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          cursor: pointer;

          img {
            width: 100%;
            height: 100px;
            object-fit: none;
            object-position: center;
            border-radius: 8px;
          }

          .event-title {
            position: absolute;
            bottom: 2rem;
            font-size: var(--font-size-body-md);
            color: var(--white);
            padding: 0 8px;
          }

          div {
            display: flex;
            justify-content: center;
            align-content: center;
            overflow: hidden;
            position: absolute;
            bottom: 1rem;
            padding-left: 8px;
          }

          &:hover {
            h6 {
              background: linear-gradient(238deg, var(--turquoise-500) -104.32%, var(--black) 100.92%);
            }
          }
        }

        h6 {
          color: var(--white);
          text-align: center;
          font-size: var(--font-size-h6-desktop);
          font-style: normal;
          font-weight: var(--font-weight-extra-bold);
          line-height: 120%;
          text-transform: lowercase;
          display: flex;
          padding: 16px 0px;
          justify-content: center;
          align-items: flex-end;
          bottom: 0px;
          background: linear-gradient(219deg, var(--turquoise-500) -145.07%, var(--black) 64.59%);
          margin-bottom: 0px;
          border-bottom: 1px solid var(--gray-700);
        }

        @include breakpoint-up(md) {
          min-height: 72px;
        }

        &.calendar__no-events {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;

          span {
            width: 100%;
          }
        }

        .calendar__day-header {
          padding-bottom: 8px;

          .calendar__day--has-passed {
            border-radius: 8px 0px 8px 0px;
            display: flex;
            width: 48px;
            height: 42px;
            padding: 12px 8px;
            justify-content: center;
            align-items: center;
            gap: 10px;

            overflow: hidden;
            color: var(--white);
            opacity: 0.2;
            text-align: center;
            text-overflow: ellipsis;
            font-size: 24px;
            font-style: normal;
            font-weight: var(--font-weight-extra-bold);
            line-height: 100%;
            letter-spacing: 2.4px;
            text-transform: uppercase;
          }

          .calendar__day--active {
            border-radius: 8px 0px 8px 0px;
            display: flex;
            width: 48px;
            height: 42px;
            padding: 12px 8px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            font-weight: var(--font-weight-extra-bold);
            background: linear-gradient(248deg, var(--gray-200) 17.66%, var(--white) 71.37%);
            color: var(--black);

            overflow: hidden;
            text-align: center;
            text-overflow: ellipsis;
            font-size: 24px;
            font-style: normal;
            font-weight: var(--font-weight-extra-bold);
            line-height: 100%;
            letter-spacing: 2.4px;
            text-transform: uppercase;
          }

          .calendar__day--shared {
            border-radius: 8px 0px 8px 0px;
            display: flex;
            width: 48px;
            height: 42px;
            padding: 12px 8px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            font-weight: var(--font-weight-extra-bold);
            color: var(--white);

            overflow: hidden;
            text-align: center;
            text-overflow: ellipsis;
            font-size: 24px;
            font-style: normal;
            line-height: 100%;
            letter-spacing: 2.4px;
            text-transform: uppercase;
          }

          .calendar__day--upcoming-event,
          .calendar__day--has-no-events {
            background: none;
            @extend .calendar__day--shared;
          }
        }

        .calendar__events {
          &.calendar__no-events {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
          }
        }

        span {
          cursor: pointer;
          color: var(--turquoise-300);
          font-size: 11px;
          font-weight: var(--font-weight-extra-bold);
          line-height: normal;
          text-wrap: wrap;
          text-align: left;

          &.calendar__day--last-index {
            border-bottom: 0px solid var(--gray-800);
          }
        }
      }
    }
  }

  .calendar__day {
    .button {
      &.calendar__event--is-disabled,
      &.calendar__event--is-disabled:hover {
        cursor: default;
        opacity: 1;
      }
    }
  }
}

.hero-container {
  position: relative;
  z-index: 5;
  background: var(--black);

  .header-title-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &.has-single-heading {
    text-align: left;
    padding-left: 8px;

    @include breakpoint-down(sm) {
      margin-top: var(--header-height-mobile);
    }

    .hero {
      padding-top: 100px;
      margin-bottom: 70px;

      @include breakpoint-up(xs) {
        padding-top: 135px;
        margin-bottom: 85px;
      }

      @include breakpoint-up(sm) {
        padding-top: 160px;
        margin-bottom: 150px;
      }

      @include breakpoint-up(md) {
        padding-top: 180px;
      }

      @include breakpoint-up(lg) {
        margin-bottom: 250px;
      }

      @include breakpoint-up(xxl) {
        margin-bottom: 460px;
      }
    }

    .hero-container__bg-img {
      height: 100%;
      background-repeat: no-repeat;

      background-position: center top;
      background-size: auto;
      background-repeat: no-repeat;
      background-attachment: fixed;

      background-size: cover;

      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    h1 {
      @include heading(display);
      margin-bottom: 32px;
      padding-top: 40px;
      font-weight: var(--font-weight-extra-bold);
      font-size: 40px;

      @include breakpoint-up(md) {
        padding-top: 110px;
      }

      @include breakpoint-up(xl) {
        padding-left: 40px;
        padding-right: 40px;
      }

      @include breakpoint-up(xxl) {
        padding-left: 0;
        padding-right: 0;
      }
    }

    p {
      @include body-copy('lg');
      margin: var(--spacing-1) auto 0 auto;
      text-align: center;
    }
  }

  &.is-short {
    .hero {
      margin-bottom: 60px;
      padding-top: 115px;

      @include breakpoint-up(xs) {
        margin-bottom: 70px;
        padding-top: 170px;
      }

      @include breakpoint-up(sm) {
        padding-top: 200px;
      }

      @include breakpoint-up(md) {
        margin-bottom: 100px;
        padding-top: 180px;
      }

      @include breakpoint-up(lg) {
        padding-top: 180px;
      }
    }
  }

  .hero {
    width: 100%;
    position: relative;
    z-index: inherit;
  }

  .hero-container__bg-img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    background-repeat: no-repeat;
    background-position: top center;
    background-attachment: scroll;
    background-size: cover;
    background-color: var(--black);

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 90px;
      min-width: 100%;
      background: var(--hero-fade-bottom-color);
    }

    &:before {
      background-color: var(--black);
      opacity: 0.5;
    }
  }

  .hero-container__bg-video {
    position: absolute;
    top: 0;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: 90px;
      min-width: 100%;
      background: var(--hero-fade-bottom-color);
    }
  }

  .details-container {
    @include breakpoint-down(sm) {
      margin-top: var(--spacing-2);
    }
  }

  .calendar-nav {
    display: flex;
    overflow: visible;
    background-color: transparent;
    transition: background-color 0.2s ease;
    max-width: fit-content;

    @media only screen and (max-width: 420px) {
      display: block;
    }

    .calendar-form-input {
      position: relative;
      font-weight: var(--font-weight-bold);
      max-width: 213px;
      width: 100%;
      height: 43px;
      padding: 8px 16px;
      gap: 8px;
      display: block;
      border-radius: 8px;
      border: 1px solid var(--white);
      background: rgba(0, 0, 0, 0.1);

      @include breakpoint-up(md) {
        font-size: var(--font-size-heading-sm-desktop);
        font-weight: var(--font-weight-light);
        width: 213px;
      }

      .options-toggler {
        display: flex;
        align-items: center;
        line-height: 100%;
        text-transform: inherit;
        color: var(--white);
        white-space: nowrap;
        font-size: var(--font-size-h6-desktop);
        font-style: normal;
        font-weight: var(--font-weight-light);
        line-height: 150%;
        gap: 8px;
      }

      .options-container {
        position: absolute;
        left: 0;
        top: 100%;
        z-index: 10;
        width: 100%;
        max-height: 500px;
        padding: 0;
        margin-top: 1px;

        border-radius: 8px;
        background: var(--black);
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);

        @include breakpoint-up(md) {
          width: 213px;
        }

        ul {
          margin: 0;
          padding: 0;

          li.option {
            padding: 8px 16px;
            list-style: none;
            cursor: pointer;
            color: var(--white);
            font-weight: var(--font-weight-light);

            &:hover {
              background: var(--white);
              color: var(--gray-950) !important;
              opacity: 1 !important;
            }

            li:hover ~ li.active {
              background: var(--white);
              color: var(--gray-950);
              opacity: 1;
            }

            &.active {
              color: var(--white);
              opacity: 0.5;
            }

            &:last-child {
              border-bottom-left-radius: 8px;
              border-bottom-right-radius: 8px;
            }
          }
        }
      }
    }

    ul {
      padding: 0px;
      margin: 0px;

      li {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 24px 0px 24px 8px;
        border-bottom: 1px solid var(--black);

        &:not(:last-child) {
          border-bottom: 1px solid var(--black);
        }

        .list-item-date-holder {
          display: flex;
          justify-content: left;

          .list-item__date {
            display: flex;
            align-items: center;
            line-height: var(--line-height-body);
            white-space: nowrap;
            text-transform: uppercase;

            span {
              color: var(--white);

              &.list-item__formatted-date {
                font-weight: var(--font-weight-extra-bold);
                color: var(--gray-100);
              }

              &.list-item__separator,
              &.list-item__formatted-day {
                font-weight: var(--font-weight-light);
              }

              &.list-item__separator {
                padding: 0 8px;
              }
            }
          }
        }

        .list-item-ctas {
          flex: 1 1;
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          grid-gap: 8px;
          gap: 8px;

          @include breakpoint-up(md) {
            padding-left: 40px;
            justify-content: flex-end;
            grid-gap: 16px;
            gap: 16px;
          }
        }
      }
    }

    @include breakpoint-up(md) {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
    }

    h4 {
      color: var(--gold);
      width: auto;
      padding: 0px;
      margin: auto 0;
    }

    svg {
      @include svg-btn(white);
    }

    .caret {
      display: flex;
      width: 14px;
      height: 14px;
      justify-content: center;
      align-items: center;
      margin-left: auto;
    }
  }

  .show-cards-container {
    display: flex;
    gap: 16px;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px 24px;

    @include breakpoint-up(md) {
      flex-direction: row;
      align-items: flex-start;
      width: 100%;
    }
  }
}
