@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.container {
  .rules-card-container {
    display: flex;
    justify-content: center;
    gap: 16px;

    @include breakpoint-up(md) {
      margin-bottom: 48px;
    }
    .tile-image {
      padding-bottom: 16px;

      @include breakpoint-up(lg) {
        padding-bottom: 24px;
      }
    }
  }

  .rules-card {
    .description-container {
      p {
        padding-top: 16px;
        font-size: var(--font-size-specialty-eyebrow-mobile);
        font-weight: var(--font-weight-light);

        @include breakpoint-up(md) {
          font-size: var(--font-size-h5-desktop);
        }
      }
    }
  }

  .carousel-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 16px;
    padding-bottom: 24px;

    .indicator {
      height: 6px;
      width: 6px;
      margin: 0 2px;
      background-color: transparent;
      border-radius: 50%;
      display: inline-block;
      border: 1px solid var(--gray-400);
      transition: background-color 0.6s ease;
      z-index: 3;
    }

    .indicator-position {
      text-align: center;
      width: 100%;
      z-index: 2;
      margin-bottom: 48px;
    }

    .active-indicator {
      background-color: var(--gray-400);
    }
  }

  .rules-card-container div {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    flex-basis: 0;
    text-align: center;
  }

  .headline-container {
    padding-bottom: 24px;
  }

  .headline {
    font-size: var(--font-size-h5-desktop);
    font-weight: var(--font-weight-extra-bold);

    @include breakpoint-up(md) {
      font-size: var(--font-size-h1-mobile);
      font-weight: var(--font-weight-light);
    }
  }
}
