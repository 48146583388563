@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.scroll {
  scroll-margin-top: 20px;
}

.card-media {
  display: flex;
  flex-wrap: wrap;
  padding-top: var(--module-spacing);
  padding-bottom: var(--module-spacing);

  @include breakpoint-up(md) {
    margin-bottom: 48px;
  }

  .col-wrapper--content {
    text-align: center;
    width: 100%;

    @include breakpoint-up(md) {
      text-align: left;
    }
  }

  &.has-media-in-right-col {
    @include breakpoint-up(md) {
      :nth-child(1) {
        order: 2;
      }

      :nth-child(1) {
        order: 1;
      }
    }
  }

  &.has-media-in-left-col {
    .col-wrapper--content {
      @include breakpoint-up(xl) {
        padding-left: 125px;
      }

      @include breakpoint-up(xxl) {
        padding-left: 40px;
      }
    }
  }

  .col-wrapper--media {
    padding-right: 0;
  }

  .col-wrapper--content {
    padding-right: 0;

    h2 {
      margin-bottom: 16px;

      + h5 {
        margin-top: -8px;
      }
    }

    h3 {
      margin-top: 16px;
    }

    h2,
    p {
      margin-top: 0;
    }

    .img-subheadline {
      margin-bottom: 5px;
    }

    p {
      @include body-copy(md);

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul {
      margin-top: 0;
      margin-bottom: var(--spacing-2);
      padding-left: var(--spacing-2);

      li {
        margin-bottom: 3px;
      }
    }

    button {
      margin-top: var(--spacing-1);
      margin-right: auto;
      margin-left: auto;

      @include breakpoint-up(md) {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
}

@include breakpoint-down(sm) {
  .media-column {
    margin-bottom: var(--spacing-1);
  }
}
