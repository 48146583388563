@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.premium-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  backdrop-filter: blur(4px);
  background: rgba(0, 0, 0, 0.1);
  border-radius: 16px 16px 0px 0px;
  height: 100%;

  .gradient-block {
    border-radius: 0 0 16px 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    @include breakpoint-up(lg) {
      border-radius: 0 0 16px 16px;
    }
  }

  @include breakpoint-up(md) {
    border-radius: 16px 16px 0px 0px;
  }

  @include breakpoint-up(lg) {
    border-radius: 16px 16px 0 0;
  }

  .image-container {
    position: relative;
    width: 100%;
    border-radius: 16px 16px 0px 0px;
    overflow: hidden;

    .media-wraper {
      padding-right: var(--spacing-0);

      .media-container {
        .media-image {
          border-radius: 16px 16px 0 0;
          min-height: 180px;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          @include breakpoint-up(lg) {
            min-height: 360px;
          }
        }

        .image-overlay {
          background: linear-gradient(180deg, rgba(0, 0, 0, 0) 78.61%, #000 100%);
          height: 100%;
          width: 100%;
          position: absolute;
          top: var(--spacing-0);
          left: var(--spacing-0);
          z-index: var(--z-index-1);

          @include breakpoint-up(md) {
            background: linear-gradient(90deg, rgba(0, 0, 0, 0) 72.81%, rgba(0, 0, 0, 0.9) 100%);
          }
        }
      }

      .video-container {
        height: 180px;
        border-radius: 16px 16px 0 0;
        overflow: hidden;

        @include breakpoint-up(lg) {
          height: 360px;
        }

        video {
          object-fit: cover;
          min-height: 180px;

          @include breakpoint-up(md) {
            min-height: 229px;
          }

          @include breakpoint-up(lg) {
            min-height: 370px;
          }
        }
      }
    }
  }

  .premium-card-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
    padding: 16px;

    @include breakpoint-up(lg) {
      padding: 14px 24px 24px 24px;
    }
    h2 {
      padding-top: 0;
      padding-bottom: 0;
    }

    .premium-card-content__inner {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;

      h4 {
        margin: 0px;
        font-size: 18px;
        font-weight: var(--font-weight-extra-bold);

        @include breakpoint-up(lg) {
          font-size: 32px;
        }
      }

      h3,
      h6 {
        width: 100%;
        margin: 0px;
        padding-bottom: 0;
      }

      p {
        margin: 0px;
        font-size: 16px;
        font-weight: var(--font-weight-light);
        line-height: 150%;
        color: var(--color-neutral-white);
      }
    }
  }

  .btn-container {
    display: flex;
    flex-direction: row;
    margin-top: auto;
    gap: 16px;
    width: 100%;
    font-weight: var(--font-weight-light);
    padding: 0 16px 16px 16px;
    flex-wrap: wrap;

    @include breakpoint-up(md) {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 16px;
    }

    @include breakpoint-up(lg) {
      padding: 0 24px 24px 24px;
    }
    &.center-aligned {
      justify-content: center;
    }

    button,
    a {
      margin: 0px;
      width: 100%;

      &:first-child {
        //margin-bottom: 8px;

        @include breakpoint-up(md) {
          margin: var(--spacing-0);
        }

        @include breakpoint-up(md) {
          width: 100%;
        }
      }
    }
  }
}
