@import '../../../../styles/variables.breakpoints.module.scss';
@import '../../../../styles/mixins.scss';

.hero-container__desktop {
  position: relative;
  z-index: 5;
  padding-bottom: 40px;

  .hero-container__background {
    position: relative;
    width: 100%;
    overflow: hidden;

    &.hero-container__background-height--third {
      height: 33vh;
    }

    &.hero-container__background-height--half {
      height: 50vh;
    }

    &.hero-container__background-height--full {
      height: 100vh;
    }

    .background-content {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;

      video,
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .details-container {
      position: absolute;
      margin: auto;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 24px;
      box-sizing: border-box;
      z-index: 10;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;

      &.hero-details-container__width--1600px {
        max-width: 1600px;
        width: 60%;
      }
      &.hero-details-container__width--1080px {
        max-width: 1080px;
        width: 100%;
      }

      .headings {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        align-self: stretch;

        h5 {
          font-size: 12px;
          letter-spacing: 2.4px;
          font-weight: 600;
          margin-bottom: 0;
        }
      }

      .description-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        p {
          line-height: 1.2;
          letter-spacing: 0.8px;
          font-weight: 350;
          font-style: normal;
          margin-bottom: 0;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        
      }

      .btn-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
      }
    }
  }
}
