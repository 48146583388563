.previewAlertContainer {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99999;
  height: 40px;
  background: white;
  color: black;
  text-align: center;
  vertical-align: center;
}
