@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.card-full-width-container {
  padding: 0 0px;

  @include breakpoint-up(sm) {
    padding: 0 15px;
  }

  .card-full-width {
    .headline-container {
      position: relative;
      padding: 0 30px;
      margin-top: -85px;
      z-index: 1;

      @include breakpoint-up(xs) {
        margin-top: -110px;
      }

      @include breakpoint-up(sm) {
        margin-top: 0px;
      }

      @include breakpoint-up(md) {
        padding: 0 45px;
        margin-top: -70px;
      }

      @include breakpoint-up(lg) {
        margin-top: -15px;
      }

      @include breakpoint-up(xl) {
        margin-top: -70px;
      }

      @include breakpoint-up(xxl) {
        margin-top: -155px;
      }

      // @TODO: This is a hack, need to find proper solution. Calendar Container components cause issue inside CardFullWidth components.
      + div {
        padding-right: 0 !important;
        padding-left: 0 !important;
        min-width: 100% !important;
      }

      h2 {
        margin-top: 0px;
        margin-bottom: 16px;
      }

      h6 {
        color: var(--gray-300);
      }
    }

    @include breakpoint-up(xxl) {
      margin-bottom: var(--spacing-6);
    }

    .image-container {
      @include breakpoint-down(xs) {
        padding-bottom: 36px;
      }

      @include breakpoint-down(md) {
        display: flex;
        justify-content: center;
      }
    }
  }

  .btn-container {
    display: block;
    width: 100%;
    @include breakpoint-up(md) {
      display: flex;
      gap: 14px;
      width: auto;
    }
    button {
      margin: auto 0px auto;
      width: 100%;
      &:first-child {
        margin-bottom: 16px;
        @include breakpoint-up(md) {
          margin: auto 0px auto;
        }
      }
      @include breakpoint-up(md) {
        width: auto;
      }
    }
  }

  .card-full-width-container__bg-img {
    height: 240px;
  }
}
