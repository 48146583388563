@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.jumpLinkGroupContainer {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
}

.jumpLinkContainer {
  display: flex;
  height: 80px;
  border-left: none;
  border-right: none;
  cursor: pointer;
  border-bottom: 1px solid var(--gray-500);
  border-top: 1px solid var(--gray-500);
  align-items: center;
  justify-content: space-between;
  margin-right: 48px;
  img {
    margin-right: 20px;
  }
  span {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    text-decoration: none;
    padding-left: 20px;
    color: var(--white);
    &:hover {
      color: var(--white);
    }
  }
}

@media (min-width: 1025px) {
  .jumpLinkContainer:nth-child(n + 4) {
    border-top: none;
  }
  .jumpLinkContainer:nth-child(3n) {
    margin-right: 0;
  }
}

@media (max-width: 1024px) {
  .jumpLinkContainer:nth-child(n + 2) {
    border-top: none;
  }
  .jumpLinkContainer {
    margin-right: 0;
  }
}
