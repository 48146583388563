@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.voting-wrapper {
  position: relative;
  z-index: 6;
  margin: 30px 0px;
  margin-bottom: 68px;

  .voting-container {
    margin-bottom: -24px;

    .vpc-heading {
      font-size: 16px;
      font-weight: var(--font-weight-bold);
      color: var(--white);
      margin-bottom: 24px;
      line-height: 100%;
    }

    .subtext {
      font-size: 14px;
      line-height: 21px;
      color: var(--gray-200);
      font-weight: 350;

      @include breakpoint-up(md) {
        font-size: 18px;
        line-height: 27px;
      }
    }

    .voting-buttons-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
    }
  }
}