$breakpoints-min: (
  'xs': 320,
  'sm': 390,
  'md': 768,
  'lg': 1128
) !default;

$breakpoints-max: (
  'xs': map-get($breakpoints-min, 'sm'),
  'sm': map-get($breakpoints-min, 'md'),
  'md': map-get($breakpoints-min, 'lg'),
  'lg': map-get($breakpoints-min, 99999)
) !default;

@mixin breakpoint-up($breakpoint) {
  @if map-get($breakpoints-min, $breakpoint) {
    $breakpoint_value: map-get($breakpoints-min, $breakpoint);

    @media (min-width: #{$breakpoint_value}px) {
      @content;
    }
  }
}

@mixin breakpoint-down($breakpoint) {
  @if map-get($breakpoints-max, $breakpoint) {
    $breakpoint_value: map-get($breakpoints-max, $breakpoint);

    @media (max-width: #{$breakpoint_value}px) {
      @content;
    }
  }
}

// Export vars to be used in js
:export {
  @each $key, $value in $breakpoints-min {
    #{unquote($key)}: $value;
  }
}
