@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.header-global {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9000;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 16px;
  overflow: visible;
  background-color: var(--black);
  transition: background-color 0.2s ease;
  justify-content: space-between;

  .height-100 {
    height: 100%;
  }
  .header-global__wrapper {
    display: flex;
    justify-content: space-between;
  }

  li {
    list-style: none;

    a,
    span {
      font-weight: var(--font-weight-light);
    }
  }

  .brand-logo {
    display: flex;
    align-items: center;
    width: var(--header-brand-logo-width-mobile);
    min-width: var(--header-brand-logo-width-mobile);
    height: var(--header-brand-logo-height-mobile);
    min-height: var(--header-brand-logo-height-mobile);
    margin-right: 24px;

    @include breakpoint-up(md) {
      width: var(--header-brand-logo-width-desktop);
      min-width: var(--header-brand-logo-width-desktop);
      height: var(--header-brand-logo-height-desktop);
      min-height: var(--header-brand-logo-height-desktop);
    }

    a {
      display: flex;
    }
  }

  &.header-global--desktop {
    height: var(--header-height-desktop);
    padding-top: 0;

    .header-global__column {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    .nav-container {
      display: flex;
      margin-left: auto;
      height: 100%;

      .ctas {
        display: flex;
        margin-left: 10px;
        margin-top: auto;
        margin-bottom: auto;
        .cta:nth-of-type(2) {
          margin-left: 24px;
        }
      }
    }

    nav {
      color: var(--white);
      transition: height 0.1s ease-out;

      > ul {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding-left: 0;
        padding-bottom: 0;
        margin: 0;
        text-align: center;

        li {
          display: flex;
          align-items: center;
          height: 100%;
          padding: 0 22.5px;
          font-weight: var(--font-weight-light);
          border: none;
          position: relative;

          &.active {
            border-bottom: 4px solid var(--brand-primary);
          }
          &:hover {
            cursor: pointer;
            span {
              color: var(--gray-100) !important;
            }
            .submenu {
              position: absolute;
              left: 0;
              margin: 0;
              top: 72px;
              display: flex;
              width: 218px;
              padding: 0px;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              border-radius: 8px;
              background: var(--gray-900);
              li {
                border-bottom: 3px solid transparent;
                align-items: flex-start;
                text-align: left;
                display: flex;
                border-bottom: 3px solid transparent;
                color: var(--white);
                padding: 0;
                &:hover {
                  background: var(--gray-800);
                  font-weight: bold;
                }
                a {
                  text-transform: none;
                  padding: 8px 42px 8px 16px;
                  display: block;
                  width: 100%;
                  text-align: left;
                  color: var(--white);
                  word-break: break-word;
                  white-space: pre-line;
                  &:hover {
                    font-weight: bold;
                  }
                }
              }
            }
          }
          ul.submenu {
            li {
              display: flex;
              align-items: flex-start;
              align-self: stretch;
              padding: 0;
              border-bottom: 3px solid transparent;
              display: flex;
              margin: 0;
              gap: 4px;
              padding: 8px 42px 8px 16px;
              a {
                color: var(--white);
              }
            }
          }
          ul.submenu {
            display: none;
          }
          &:hover {
            // border-bottom: 4px solid var(--brand-primary);
          }

          .menu-item-wrapper {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 0 var(--spacing-1);
            cursor: pointer;
            display: block;
            padding: 0;
            white-space: nowrap;

            .is-active-link {
              font-weight: var(--font-weight-semi-bold);

              &:user-select {
                border-color: var(--brand-primary);
                border-bottom: 4px;
              }
            }

            .caret {
              position: absolute;
              left: 50%;
              top: 33px;
              transform: translate(-50%, -50%);

              svg {
                opacity: 50%;
                height: 10px;
                width: 10px;
              }
            }

            a,
            span {
              @include link(gray-300);
              &:hover {
                @include link(gray-100);
              }
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  &.header-global--mobile {
    height: var(--header-height-mobile);

    .header-global__column {
      display: flex;

      .cta {
        margin-left: 16px;

        button {
          @include breakpoint-down(sm) {
            @include btnSize(sm);
          }
        }
      }
    }

    .nav-drawer-toggler {
      display: flex;
      align-items: center !important;
      margin-right: 16px;

      svg {
        @include svg-btn(white);
      }
    }

    .brand-logo {
      margin-right: auto;
    }

    &.drawer-open {
      width: 100%;
      height: 100%;
      backdrop-filter: blur(12.5px);
      background: rgb(27, 27, 27, 0.75); //store in global variable

      // hide due to position shift
      .header-global__column,
      .header-global__column + .brand-logo {
        display: none;
      }
    }

    nav {
      position: absolute;
      top: 0;
      left: -320px;
      height: 100vh;
      width: 320px;
      z-index: 1000;
      background-color: var(--black);
      transition: height 0.1s ease-out;
      letter-spacing: 0.02em;
      max-width: 100%;
      transition: all 0.1s;

      &.is-open {
        left: 0;
      }

      .nav-drawer__header {
        display: flex;
        align-items: center;
        height: var(--header-height-mobile);
        padding: 0 16px;

        .nav-drawer-toggler {
          display: flex;

          svg {
            @include svg-btn(white);
          }
        }
      }

      .nav-drawer__scroller {
        height: calc(100% - var(--header-height-mobile));
        padding-bottom: 120px;
        overflow: auto;
        background-color: var(--gray-950);

        ul {
          width: 100%;
          padding: 0;

          a {
            @include link(white);
          }

          li {
            padding: 28px 0;
            font-size: var(--font-size-heading-sm-mobile);

            &:last-child {
              border: none;
            }

            &.is-open {
              .caret {
                transform: rotate(90deg);
              }
            }
            &.is-open:hover {
              background-color: initial;
            }

            .menu-item-wrapper {
              position: relative;
              display: flex;
              justify-content: space-between;
              width: 100%;
              padding: 0 var(--spacing-1);
              cursor: pointer;

              .caret svg {
                width: 13.5px;
                transform: rotate(270deg);
              }
            }

            .submenu {
              li {
                margin-top: 16px;
                padding: 16px 24px 16px 40px;
                border: none;
                text-transform: none;
              }
            }
          }
          li:hover {
            background-color: var(--gray-800);
          }
        }

        .nav-drawer__ctas {
          padding: 24px 20px;
          text-align: center;
          background-color: var(--gray-950);
          box-shadow: var(--box-shadow-mobile-header-ctas);

          .cta {
            + .cta {
              margin-top: 15px;
            }

            button {
              @include btnSize(lg);
              @include btnFullWidth();
            }
          }
        }
      }
    }
  }
}
