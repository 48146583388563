@import '../../../styles/variables.breakpoints.module.scss';

@keyframes textEaseIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-scroll-animate-scene {
  width: 100%;
  height: 100%;

  position: fixed;
  left: 50%;
  top: 180px;
  z-index: 1;

  text-align: center;

  transform: translateX(-50%);
  animation: textEaseIn 0.5s alternate ease-in-out;

  button {
    margin-top: var(--spacing-1);
  }

  @include breakpoint-up(sm) {
    top: 190px;

    p {
      margin: 0 auto;
      width: 820px;
      max-width: 100%;
    }
  }

  // For most of the breakpoints, the footer height is actually 150px, need to add that instead of 100px.
  @include breakpoint-up(sm) {
    top: 132px;
  }

  @include breakpoint-up(md) {
    top: 186px;
  }

  @include breakpoint-up(lg) {
    top: 186px;
  }

  @include breakpoint-up(xxl) {
    top: 180px;
  }
}

.animate-scroll-animation-scene-carousel {
  top: 0;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}
