@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.career-form {
  margin-top: var(--spacing-3);
  color: var(--gray-700);

  button {
    height: 70px;
    width: 100%;
    border-radius: 48px !important;
  }

  .career-form-input {
    @include body-copy(lg);
    position: relative;
    background-color: var(--white);
    width: 100%;
    float: left;
    margin-bottom: 10px;

    ul {
      margin: 0;
      padding: 0;

      li.active,
      li:hover {
        color: var(--gray-900);
        font-weight: var(--font-weight-semi-bold);
      }
    }
  }

  .options-toggler {
    display: flex;
    align-items: center;
    height: 69px;
    padding: 11px 22px;

    &:after {
      content: '';
      position: absolute;
      right: 15px;
      top: 30px;
      width: 0;
      height: 0;
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
      border-top: 10px solid var(--gray-700);
    }

    &.open:after {
      transform: rotate(180deg);
    }
  }

  .options-container {
    max-height: 400px;
    overflow: scroll;
    padding: 0;
    margin: 0;
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 10;
    width: 100%;
    background-color: var(--white);

    .option {
      list-style: none;
      padding: 11px 21px;
      margin: 7px 0;
      cursor: pointer;
      text-align: left;
    }
  }

  @include breakpoint-up(md) {
    .career-form-input {
      width: 37%;
      margin-right: 1%;
      margin-bottom: 0;
    }

    button {
      width: 24%;
    }
  }
}
