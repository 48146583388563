@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.show-cards-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 16px;
  height: 100%;
  padding-bottom: 40px;

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .card-column-0 {
    grid-area: 1 / 1 / 3 / 2;
    height: 100%;

    min-height: 630px;

    @media (max-width: 768px) {
      min-height: 560px;
    }

    @media (max-width: 600px) {
      min-height: 313px;
      display: grid;
    }
  }

  .card-column-1 {
    grid-area: 1 / 2 / 2 / 3;
    height: 100%;

    @media (max-width: 600px) {
      min-height: 313px;
      display: grid;
    }
  }

  .card-column-2 {
    grid-area: 2 / 2 / 3 / 3;
    height: 100%;

    @media (max-width: 600px) {
      min-height: 313px;
      display: grid;
    }
  }

  .card-column-3 {
    grid-area: 3 / 1 / 4 / 2;
    height: 307px;

    @media (max-width: 600px) {
      min-height: 313px;
      display: grid;
    }
  }

  .card-column-4 {
    grid-area: 3 / 2 / 4 / 3;
    height: 307px;

    @media (max-width: 600px) {
      min-height: 313px;
      display: grid;
    }
  }

  @media (max-width: 600px) {
    .card-column-0,
    .card-column-1,
    .card-column-2,
    .card-column-3,
    .card-column-4 {
      min-height: 313px;
      display: grid;
      height: 100%;
    }
  }

  .full-height-single-column {
    grid-area: 1 / auto / 3 / span 1;
    height: 100%;
    min-height: 630px;

    @media (max-width: 768px) {
      min-height: 560px;
    }

    @media (max-width: 600px) {
      min-height: 313px;
      display: grid;
      height: 100%;
    }
  }
}

.card-section {
  display: flex;
  gap: 40px;
  flex-direction: column;
  padding: 32px 16px;
  margin: auto;

  @include breakpoint-up(md) {
    padding: 32px 24px;
    gap: 48px;
  }

  @include breakpoint-up(xl) {
    padding: 0;
  }

  @include breakpoint-up(lg) {
    gap: 40px;
    max-width: 1648px; //Accounts for 24px in padding-left and padding-right
    padding: 72px 24px;
  }

  h6 {
    margin: 0px;
    padding-bottom: 8px;
  }

  h2 {
    margin: 0px;
    padding: 0;
    font-weight: var(--font-weight-extra-bold);
    font-size: 24px;
    text-shadow: 0px 0px 16px var(--black);

    @include breakpoint-up(md) {
      font-size: 40px;
    }

    @include breakpoint-up(lg) {
      font-size: 48px;
      line-height: 120%;
    }
  }

  .mobile-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 24px;
  }

  .carousel-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 16px;
    padding-bottom: 24px;

    .indicator {
      height: 6px;
      width: 6px;
      margin: 0 2px;
      background-color: transparent;
      border-radius: 50%;
      display: inline-block;
      border: 1px solid var(--gray-400);
      transition: background-color 0.6s ease;
      z-index: 3;
    }

    .indicator-position {
      text-align: center;
      width: 100%;
      z-index: 2;
    }

    .active-indicator {
      background-color: var(--gray-400);
    }
  }
}
