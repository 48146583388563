@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

$video-offset-top: 75px;

.quote-container {
  position: absolute;
  z-index: 1;
  border-radius: 16px;
  border: 1px dashed var(--gray-500, #757575);
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  gap: 24px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 288px;
  max-width: 570px;
  padding: 40px 16px;

  blockquote {
    margin: 0px;
  }

  @include breakpoint-up(md) {
    right: 10%;
    bottom: 30%;
    left: unset;
    top: unset;
    transform: unset;
    min-width: 570px;
    max-width: 570px;
    padding: 40px;
  }

  h4 {
    color: var(--gray-100);
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 120%;
    margin-bottom: 0px;
    @include breakpoint-up(sm) {
      font-size: 32px;
    }
    span {
      color: var(--gray-100);
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;

      @include breakpoint-up(sm) {
        font-size: 32px;
      }
    }
    p {
      color: var(--gray-100);
      font-size: 24px;
      font-style: normal;
      font-weight: 300;
      line-height: 120%;
      display: initial;
      padding-left: 8px;

      @include breakpoint-up(sm) {
        font-size: 32px;
      }
    }
  }
  .sub-headline {
    h2 {
      color: var(--gold);
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      margin-top: 15px;
      margin-bottom: 8px;

      @include breakpoint-up(sm) {
        font-size: 20px;
      }
    }
    p {
      color: var(--white);
      font-size: 11px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
      letter-spacing: 2.4px;
      text-transform: uppercase;
      margin-bottom: 0px;

      @include breakpoint-up(sm) {
        font-size: 12px;
      }
    }
  }
  &:before {
    content: '';
    background: url(/assets/quote-left.png);
    top: -1rem;
    left: -1rem;
    width: 42px;
    height: 42px;
    display: block;
    position: absolute;
  }
  &:after {
    content: '';
    background: url(/assets/quote-right.png);
    bottom: -1rem;
    right: -1rem;
    width: 42px;
    height: 42px;
    display: block;
    position: absolute;
  }
}
