@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.cohort-wrapper {
  position: relative;
  z-index: 6;
  margin: 30px 0px;
  margin-bottom: 68px;

  .cohort-container {
    margin-bottom: -24px;

    .cohort-heading-text {
      font-size: var(--font-size-h4-mobile);
      font-weight: var(--font-weight-bold);
      color: var(--white);
      margin-bottom: 24px;
      line-height: 100%;

      @include breakpoint-up(md) {
        font-size: var(--font-size-h1-mobile);
        font-weight: var(--font-weight-light);
        margin-bottom: 16px;
      }

      @include breakpoint-up(lg) {
        font-size: var(--font-size-h2-desktop);
        font-weight: var(--font-weight-extra-bold);
        margin-bottom: 32px;

      }

      @media (min-width: 1440px) {
        font-size: var(--font-size-h1-mobile);
        font-weight: var(--font-weight-light);
        
      }
    }

    .cohort-buttons-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;

      @include breakpoint-up(md) {
        grid-template-columns: repeat(4, 1fr);
      }
    }

    .cohort-buttons-container button {
      height: 92px;

      span {
        color: var(--white);
        font-size: var(--font-size-h4-mobile);
        font-weight: var(--font-weight-extra-bold);
      }

      @include breakpoint-up(md) {
        height: 110px;

        span {
          font-size: var(--font-size-h3-desktop);
          font-weight: var(--font-weight-light);
          line-height: 24px;
          cursor: pointer;
        }
      }

      @include breakpoint-up(lg) {
        height: 132px;

        span {
          font-size: var(--font-size-h1-mobile);
        }
      }
    }
  }
}
