@import '../../../styles/variables.breakpoints.module.scss';

.icon-buttons-card {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100vw;
  margin-top: 5rem;
  overflow: hidden;
}

.background-video {
  object-fit: contain;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 0;
}

.content-wrapper {
  margin-top: 5rem;
  display: flex;
  flex-direction: column;

  @include breakpoint-up(sm) {
    margin-top: 11rem;
  }

  @include breakpoint-up(lg) {
    margin-top: 16rem;
  }
}

.eyebrow {
  opacity: 0;
  text-align: center;
  z-index: 1;

  * {
    text-transform: uppercase;
  }

  span {
    font-size: 11px;
    letter-spacing: 0.2rem;
    @include breakpoint-up(lg) {
      font-size: var(--font-size-body-xs);
    }
  }
}

.headline {
  opacity: 0;
  text-align: center;
  z-index: 1;
  padding: 0 3rem;

  h1 {
    font-weight: var(--font-weight-extra-bold);
  }

  span {
    font-size: var(--font-size-h2-mobile);

    @include breakpoint-up(md) {
      font-size: var(--font-size-h2-desktop);
    }

    @include breakpoint-up(lg) {
      font-size: var(--font-size-h1-desktop);
    }
  }
}

.cards-container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  z-index: 1;
  margin: 40px 0;
  gap: 8px;

  @include breakpoint-up(md) {
    margin: 96px 0;
    gap: 8px;
  }

  @include breakpoint-up(lg) {
    margin: 100px 0;
    gap: 16px;
  }
}

.icon-button {
  display: flex;
  flex-direction: column;
  height: 80px;
  margin-top: 30px;

  @include breakpoint-up(sm) {
    height: 100px;
    margin: 8px 0;
  }
  @include breakpoint-up(md) {
    height: 200px;
    margin: 8px 0;
  }
}

.two-per-row {
  width: 43%;
}

.three-per-row {
  width: 30%;
}

.four-per-row {
  width: 23%;
}

.hover-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  opacity: 0;
  position: relative;

  &::after {
    background: radial-gradient(at 50% 10%, rgb(63 40 83 / 30%) 10%, rgb(30 24 32 / 30%) 80%);
    border-radius: 8px;
    bottom: -45px;
    content: '';
    height: 64px;
    top: 1.5rem;
    position: absolute;
    transition: height 0.3s ease-in-out, background-color 0.3s ease-in-out;
    width: 100%;
    z-index: -1;

    @include breakpoint-up(sm) {
      height: 80px;
      top: 20px;
    }

    @include breakpoint-up(md) {
      height: 160px;
      top: 50px;
    }

    @include breakpoint-up(lg) {
      height: 160px;
    }
  }
}

.icon {
  position: relative;
  transition: transform 0.3s ease-in-out, color 0.3s ease-in-out;
  width: 40px;
  height: 40px;

  @include breakpoint-up(md) {
    height: 80px;
    width: 80px;
    margin: 10px;
  }
}

.text {
  color: var(--Neutral-White, #fff);
  font-family: var(--font-family-default);
  font-style: normal;
  font-weight: var(--font-weight-semi-bold);
  line-height: 16px;
  opacity: 0;
  text-align: center;
  text-transform: lowercase;
  transition: color 0.3s ease-in-out;
  padding: 6px 0;
  font-size: var(--font-size-h5-mobile);

  @include breakpoint-up(sm) {
    line-height: 20px;
  }
  @include breakpoint-up(md) {
    padding: 16px 0;
    font-size: var(--font-size-h3-desktop);
  }

  @include breakpoint-up(lg) {
    font-size: var(--font-size-h2-mobile);
    padding: 22px 0;
  }
}

.icon-button:hover .icon {
  transform: translateY(-10px);
}

.icon-button:hover .hover-container::after {
  background: radial-gradient(at 50% 10%, rgba(111, 67, 132, 0.8) 10%, rgba(111, 67, 132, 0.1) 80%);
}

.animate {
  animation: moveUp 1s ease-out forwards;
}

.delay-short {
  animation-delay: 0.3s;
}

.delay-long {
  animation-delay: 1s;
}

@keyframes moveUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}
