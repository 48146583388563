@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.partnership-icons {
  ul {
    &.is-aligned-center {
      text-align: center;
    }

    li {
      display: inline-block;

      img {
        width: auto;
        height: 15px;
        margin: 16px;
      }
    }
  }

  h6 {
    color: var(--gray-300) !important;
    font-size: var(--font-size-specialty-eyebrow-mobile);
    font-weight: var(--font-weight-extra-bold);
    text-transform: none;

    @include breakpoint-up(lg) {
      font-size: var(--font-size-h6-desktop);
    }
  }
}
