@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.close-button-wrapper {
  cursor: pointer;
  display: flex;
  width: 24px;
  height: 24px;
  padding: 6px 5.979px 5.979px 6px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
