@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.notification-banner {
  position: relative;
  width: 100%;
  top: 0;
  background: var(--banner-notification-bg-color);
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  .container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    .notification_banner__content {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      gap: 12px;
      @include breakpoint-up(md) {
        gap: 8px;
      }
      .notification_banner__text {
        text-align: center;
        width: 100%;
        flex-direction: row;
        p {
          margin: 0;
          font-size: var(--font-size-body-xs);
          flex: 1;
          text-align: center;
          @include breakpoint-up(md) {
            font-size: var(--font-size-body-sm);
          }

          @include breakpoint-up(lg) {
            font-size: var(--font-size-body-md);
          }
        }
      }
      .notification_banner__button {
        align-items: center;
        text-align: center;
        display: flex;
        justify-content: center;
        width: 100%;
        flex-direction: row;
        flex-basis: 45%;
        @include breakpoint-up(md) {
          width: auto;
          flex-basis: auto;
        }
        button {
          letter-spacing: 0.05em;
        }
      }
    }
  }
}
