@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

$video-offset-top: 75px;
$headline-height-desktop: 45px;
$headline-height-mobile: 32px;

.edp-full-screen-video {
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  overflow: hidden;
  transition: all 0.5 ease;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: var(--z-index-1);

  .container {
    height: 100%;
    z-index: var(--z-index-1);
  }
  .cta-container {
    position: absolute;
    bottom: 20px;
    right: 0;
    width: 100%;
    text-align: center;
    button,
    a {
      font-family: Graphie;
      font-weight: 400 !important;
      line-height: 22px !important;
      padding: 16px 32px !important;
      color: var(--gray-950) !important;
      font-weight: var(--font-weight-semi-bold) !important;
    }
    a {
      display: inline-block !important;
    }
    @include breakpoint-up(md) {
      bottom: 150px;
    }
  }
  .top-bg-gradient {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 8999;
    width: 100%;
    background: linear-gradient(178deg, #000 4.94%, rgba(0, 0, 0, 0) 43.47%);
    height: 74px;
  }
  .bottom-bg-gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 8999;
    width: 100%;
    background: linear-gradient(190deg, #000 18.77%, rgba(0, 0, 0, 0) 49.56%);
    height: 74px;
  }
}
