@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.tabs {
  width: 100%;
  text-align: center;
  margin-top: var(--spacing-1);
  background: linear-gradient(90deg, transparent 0%, var(--gray-900) 48.96%, transparent 100%);

  @include breakpoint-up(lg) {
    margin-top: var(--spacing-2);
  }

  .tabs__wrapper {
    white-space: nowrap;

    .tabs__scrollable-container {
      overflow-x: visible;
      overflow-y: auto;
    }
  }

  .tab {
    position: relative;
    display: inline-block;
    padding: 15px 18px;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    margin: 0;
    white-space: no-wrap;

    @include breakpoint-up(sm) {
      padding-right: 23px;
      padding-left: 23px;
    }

    &:hover {
      color: var(--white);
      transition: var(--link-color-transition);
    }

    &.is-active {
      color: var(--white);

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -3px;
        width: 100%;
        height: 3px;
        background-color: var(--white);
      }
    }
  }
}
