@import '../../../styles/variables.breakpoints.module.scss';
@import '../../../styles/mixins.scss';

.combination-header__container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.notification-banner {
  position: fixed;
  width: 100%;
  height: auto;
  z-index: 9000;
  top: 0;
}

.navigation-header-global {
  width: 100%;
  position: relative;
  z-index: 9000;

  &.with-banner {
    margin-top: 0;
  }
}
