@import '../../../../../styles/variables.breakpoints.module.scss';
@import '../../../../../styles/mixins.scss';

.shows-modal {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(var(--black-rgb), 0.5);

  &.modal-form-result {
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(15px);
  }
  svg {
    @include svg-btn(white);
    position: absolute;
    top: 20px;
    right: 15px;

    + h3 {
      margin-top: 0;
      padding-right: 30px;
    }
  }

  .modal-dialog {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
  }

  .modal-content {
    position: relative;
    padding: var(--spacing-6) var(--spacing-1) var(--spacing-6) var(--spacing-1);

    &.modal-content--narrow {
      max-height: calc(100vh - var(--spacing-3) - 10px);
      width: 660px;
      overflow-y: auto;
      background-color: var(--black);

      // Assumes button is at bottom of modal
      button:last-child {
        @include breakpoint-down(sm) {
          // required to prevent iOS Chrome bottom bar from overlapping
          // do not change unless specifically required
          margin-bottom: var(--spacing-4);
        }
      }
    }

    &.modal-content--wide {
      width: 100%;
      max-width: 100%;
      margin-top: calc(var(--spacing-2) * -1);
      max-height: 90vh;

      @include breakpoint-up(lg) {
        max-width: 1125px;
      }

      svg {
        top: 0;
        right: 0;
      }
    }

    &.modal-content--gray {
      width: 100%;
      max-width: 470px;
      padding-top: 40px;
      padding-bottom: 15px;
      background: var(--gray-800);
      border: 2px solid var(--gray-500);
      border-radius: 8px;
      text-align: center;

      @include breakpoint-up(xs) {
        padding-bottom: 25px;
      }

      @include breakpoint-up(sm) {
        padding-top: 65px;
        padding-bottom: 60px;
      }

      @include breakpoint-up(lg) {
        max-width: 640px;
        padding-top: 100px;
        padding-bottom: 95px;
      }

      h4 {
        @include heading(label);
        color: var(--gray-100);
      }

      h3 {
        line-height: 48px;

        + p {
          color: var(--gray-100);

          span {
            color: var(--white);
            font-weight: var(--font-weight-bold);
          }
        }
      }

      button {
        margin-top: 20px;
      }

      svg {
        @include svg-btn(white);
        top: 15px;
      }
    }

    &.modal-content--shows-calendar {
      width: 100%;
      max-width: 470px;
      padding-top: 40px;
      padding-bottom: 15px;
      background: var(--gray-800);
      border: 0;
      text-align: center;
      border-radius: 8px;
      background: var(--gray-900);

      @include breakpoint-up(xs) {
        padding-top: 24px;
        padding-bottom: 24px;
      }

      @include breakpoint-up(sm) {
        padding-top: 24px;
        padding-bottom: 24px;
      }

      @include breakpoint-up(lg) {
        max-width: 600px;
        padding-top: 24px;
        padding-bottom: 24px;
      }

      h6 {
        color: #fcfcfc;
        text-align: center;
        text-transform: none;
        font-size: 22px;
        font-style: normal;
        font-weight: 350;
        line-height: 120%;
      }
      h2 {
        font-weight: 350;
        font-size: 32px;
      }
      h4 {
        @include heading(label);
        color: var(--gray-100);
      }

      h3 {
        line-height: 48px;

        + p {
          color: var(--gray-100);

          span {
            color: var(--white);
            font-weight: var(--font-weight-bold);
          }
        }
      }

      p {
        margin-bottom: 0px;
      }
      a,
      button {
        margin-top: 32px;
        margin-bottom: 0px;
      }

      svg {
        // @include svg-btn(white);
        color: #919191;
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
        top: 24px;
        transition: var(--link-color-transition);
        cursor: pointer;
        text-decoration: none;
        &:hover {
          color: var(--link-color-white-hover);
        }
      }
    }

    &.modal-content--form-result {
      width: 100%;
      max-width: 272px;
      padding-top: 40px;
      padding-bottom: 30px;
      padding-left: 22px;
      padding-right: 22px;
      background: var(--gray-800);
      text-align: center;
      border-radius: 16px;
      background-color: var(--form-result-bg-color);
      backdrop-filter: blur(4px);
      box-shadow: none;
      img {
        margin-bottom: 12px;
      }
      h4 {
        @include heading(label);
        color: var(--gray-100);
        font-weight: var(--font-weight-extra-bold);
        font-size: var(--font-size-h4-desktop);
        p {
          line-height: 120%;
          margin: 0;
          font-size: var(--font-size-body-md);
          font-weight: var(--font-weight-semi-bold);
          line-height: 120%;
          color: var(--gray-100);
        }
      }

      h5 {
        @include heading(label);
        color: var(--gray-100);
        font-weight: var(--font-weight-extra-bold);
        p {
          line-height: 120%;
          margin: 0;
          font-size: var(--font-size-h5-desktop);
          font-weight: var(--font-weight-semi-bold);
          line-height: 120%;
          color: var(--gray-100);
        }
      }

      h3 {
        line-height: 48px;

        + p {
          color: var(--gray-100);

          span {
            color: var(--white);
            font-weight: var(--font-weight-bold);
          }
        }
      }

      button {
        margin-top: 20px;
      }

      svg {
        top: 20px;
        right: 20px;
        height: 11px;
        width: 11px;
        max-height: 11px;
        max-width: 11px;
        color: var(--gray-500);
      }
    }

    &.modal-content--transparent {
      width: 100%;
      max-width: 470px;
      background: var(--gray-800);
      border-radius: 16px;
      text-align: center;
      padding: 0;
      @include breakpoint-up(md) {
        max-width: 714px;
      }
      @include breakpoint-up(lg) {
        max-width: 924px;
      }
      @include breakpoint-up(xl) {
        max-width: 1080px;
      }

      h4 {
        @include heading(eyebrow);
        color: var(--gray-100);
      }

      h3 {
        + p {
          color: var(--gray-100);

          span {
            color: var(--white);
            font-weight: var(--font-weight-bold);
          }
        }
      }

      button {
        margin-top: var(--spacing-1);
      }

      svg {
        @include svg-btn(white);
        top: 12px;
        right: 12px;
        z-index: 20;
        filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.8));
      }
    }
  }
}
