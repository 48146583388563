@import '../../../styles/variables.breakpoints.module.scss';

.container {
  display: grid;
  place-items: center;
}

.title {
  text-align: center;
  margin: 320px 0 40px 0;
  font-family: var(--font-family-default);
  font-size: 32px;
  letter-spacing: 8px;
  font-weight: 600;
  color: var(--gold);
  font-style: normal;
  line-height: 100%;
  text-transform: uppercase;
}

.logo-container {
  margin-bottom: 144px;
  vertical-align: middle;
}

@include breakpoint-up('md') {
  .logo {
    width: 634px;
  }
  .title {
    letter-spacing: 6.4px;
    margin: 393px 0 22px 0;
  }
  .logo-container {
    margin-bottom: 88px;
  }
}

@include breakpoint-down('sm') {
  .logo {
    width: 288px;
  }
  .title {
    font-size: 20px;
    letter-spacing: 4px;
    margin: 200px 0 12px 0;
    width: 290px;
  }
  .logo-container {
    margin-bottom: 88px;
  }
}
